import React from "react";
import { Editor as TinyMCEEditor } from "@tinymce/tinymce-react";

/**
 * NB! When used inside a ChakraUI modal, please add these to the component.
 * It solves the link-plugin issue that makes the form not interactable.
 * <Modal
 * blockScrollOnMount={false} trapFocus={false} returnFocusOnClose={false} />
 */

interface TinyEditorProps {
  content: string;
  onContentChange: (content: string) => void;
}

export const sleep = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const CommentEditor: React.FC<TinyEditorProps> = ({
  content,
  onContentChange,
}) => {
  return (
    <React.Fragment>
      <>
        <TinyMCEEditor
          apiKey="6jdsnk9zbj86ga29pbk02e3rermf0wersu1ltm0umkflyoju" //Personal API key, made on user Anna Linnerud can be changed to another user
          //ref={editor}
          value={content}
          onEditorChange={(newContent) => {
            onContentChange(newContent);
          }}
          init={{
            //editor configurations
            placeholder: "Write your comment here...",
            height: 200,
            menubar: false,
            removed_menuitems: "code",
            language: "en",
            plugins: [
              "link",
              "autolink",
              "charmap",
              "code",
              "lists",
              "preview",
              "table",
              "visualblocks",
              "wordcount",
              "advlist",
              "codesample",
            ],
            toolbar: "bold italic underline font",

            statusbar: true,
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:12px }",
          }}
        />
      </>
    </React.Fragment>
  );
};

export default CommentEditor;
