import { Box, Button, Icon, Stack, useToast, Text } from "@chakra-ui/react";
import { Table, Td, Th, Tr } from "../Global/Table";
import React, { useEffect, useState } from "react";
import { Tenant } from "../../Models/Tenant";
import { Assignment } from "../../Models/Assignment";
import {
  deleteAssignmentById,
  getAllMembers,
} from "../../Services/CommandCenterService";
import AddAssignmentModal from "./AddAssignmentModal";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useAppDispatch } from "../../hooks";
import { deleteAssignment } from "../../Features/Tenants/tenantSlice";
import DeleteAlertDialog from "../Global/DeleteAlertDialog";

interface AssignmentsTableProps {
  tenants: Tenant[];
  assignments: Assignment[];
}

const AssignmentsTable: React.FC<AssignmentsTableProps> = ({
  assignments,
  tenants,
}) => {
  const [members, setMembers] = useState<{ id: string; displayName: string }[]>(
    []
  );
  const [tenant, setTenant] = useState<Tenant>();
  const [isOpen, setIsOpen] = useState(false);
  const toast = useToast();
  const dispatch = useAppDispatch();

  const handleAssignment = (tenant: Tenant) => {
    setTenant(tenant);
  };

  const handleDelete = async (id: number) => {
    await deleteAssignmentById(id);

    dispatch(deleteAssignment(id));

    toast({
      title: "Success!",
      description: `Assignment deleted`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  useEffect(() => {
    async function fetchGroupMembers() {
      try {
        const groupId = "cc08e2b1-8db3-45f5-8b86-bbc563a1883b"; // pt-cloudcontrol-keyvaults
        const groupMembers = await getAllMembers(groupId);
        setMembers(groupMembers);
      } catch (error) {
        console.error("Failed to retrieve group members:", error);
      }
    }

    fetchGroupMembers();
  }, []);

  useEffect(() => {
    tenant && setIsOpen(true);
  }, [tenant]);

  const ScrollableTableCell = ({ children }) => (
    <Box overflowY="auto" maxHeight="100px">
      {children}
    </Box>
  );

  return (
    <>
      {tenant && (
        <AddAssignmentModal
          isOpen={isOpen}
          tenant={tenant}
          onClose={() => {
            setTenant(undefined);
            setIsOpen(false);
          }}
          onSave={() => {}}
        />
      )}
      <Table minW="100%">
        <thead>
          <Tr>
            <Th>Azure Tenant Id</Th>
            <Th>Tenant Name</Th>
            <Th>Assigned To</Th>
            <Th></Th>
          </Tr>
        </thead>
        <tbody>
          {tenants.map((t, index) => {
            const assignedUsers = assignments.filter(
              (assignment) => assignment.azureTenantId === t.azureTenantId
            );

            return (
              <Box as="tr" key={t.id} textAlign="start" verticalAlign={"top"}>
                <Td fontSize="sm" fontWeight="small">
                  {t.azureTenantId}
                </Td>
                <Td fontSize="sm" fontWeight="small">
                  {t.azureTenantName}
                </Td>
                <Td fontSize="sm" fontWeight="small">
                  <ScrollableTableCell>
                    {assignedUsers.map((assignment, index) => {
                      // Find the corresponding member to display displayName
                      const member = members.find(
                        (member) => member.id === assignment.userId
                      );
                      const displayName = member
                        ? member.displayName
                        : "Unknown User";

                      return (
                        <React.Fragment key={index}>
                          <Stack direction="row" spacing={1}>
                            <Text>{displayName}</Text>
                            <DeleteAlertDialog
                              onDelete={() => handleDelete(assignment.id!)}
                              isCross={true}
                              item={`${displayName} from ${t.azureTenantName}?`}
                            />
                          </Stack>
                          {index < assignedUsers.length - 1 && (
                            <Box height="4px"></Box>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </ScrollableTableCell>
                </Td>
                <Td>
                  <Button
                    onClick={() => handleAssignment(t)}
                    leftIcon={<Icon as={PersonAddIcon} w={5} h={5} />}
                    size="sm"
                  >
                    Add Consultant
                  </Button>
                </Td>
              </Box>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default AssignmentsTable;
