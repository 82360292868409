import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import {
  allCategories,
  addTemplateAction,
} from "../../Features/Config/configSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { TemplateAction } from "../../Models/TemplateAction";
import { createTemplateAction } from "../../Services/CommandCenterService";
import TinyEditor from "../Global/TinyEditor";

interface FormValues {
  description: string;
  shortText: string;
  category: string;
}

const AddTemplateActionModal = ({ children }) => {
  const categories = useAppSelector(allCategories);
  //const activeTenantSlice = useAppSelector(activeTenant);
  const dispatch = useAppDispatch();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  //const tenant = activeTenantSlice?.tenant;

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
    control,
  } = useForm<FormValues>();

  const onCreateTemplate = async ({ description, shortText, category }) => {
    const selectedCategoryObj = categories.find(
      (c) => c.id === Number(category)
    );

    const newTemplate: TemplateAction = {
      description: description,
      shortText: shortText,
      categoryId: selectedCategoryObj!.id!,
      category: {
        name: selectedCategoryObj!.name,
      }!,
    };

    const result = await createTemplateAction(newTemplate);
    const res = await result.json();

    // Update the UI with the newly created action in the database
    dispatch(addTemplateAction(res));

    toast({
      title: "Success!",
      description: `Template action was created.`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    reset();

    onClose();
  };

  return (
    <>
      <Button
        onClick={onOpen}
        size="sm"
        backgroundColor="gray.900"
        color="white"
        fontWeight="medium"
        _hover={{ bg: "gray.700" }}
        _active={{
          bg: "gray.800",
          transform: "scale(0.95)",
        }}
      >
        {children}
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="6xl"
        blockScrollOnMount={false}
        trapFocus={false}
        returnFocusOnClose={false}
      >
        <ModalOverlay style={{ zIndex: 1280 }} />
        <ModalContent as="form" onSubmit={handleSubmit(onCreateTemplate)}>
          {/* <ModalContent as="form"> */}
          <ModalHeader fontWeight="bold">Add Template Action</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Stack direction={"row"} spacing={2} align={"start"}>
              <FormControl isInvalid={!!errors.shortText} w={"80%"}>
                <FormLabel>Title</FormLabel>
                <Input
                  placeholder="Template short description"
                  id="shortText"
                  {...register("shortText", {
                    required: "This is required",
                    maxLength: 128,
                  })}
                />
                <FormErrorMessage>
                  {errors.shortText?.type === "maxLength" && (
                    <span>Max 128 characters</span>
                  )}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.description} w="20%">
                <FormLabel>Category</FormLabel>
                <Select
                  id="category"
                  {...register("category", {
                    required: "This is required",
                  })}
                  placeholder="Select category"
                  width="auto"
                >
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <FormControl mt={4} isInvalid={!!errors.description}>
              <FormLabel>Image Gallery</FormLabel>
              <Controller
                name="description"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TinyEditor
                    content={field.value}
                    onContentChange={field.onChange} // onChange updates the form's state
                    editorTitle="Description"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.description &&
                typeof errors.description.message === "string"
                  ? errors.description.message
                  : undefined}
              </FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} mr={3} fontWeight="medium">
              Cancel
            </Button>
            <Button
              isLoading={isSubmitting}
              colorScheme="orange"
              fontWeight="medium"
              type="submit"
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddTemplateActionModal;
