import { extendTheme } from "@chakra-ui/react";
import React from "react";

const customIcons = {
  logo: {
    path: (
      <path
        d="M19.557.113C11.34.32 9.117 8.757 9.03 12.95c1.643-2.67 4.62-3.08 6.931-3.08 2.825.085 10.27.205 17.458 0C40.61 9.663 44.802 3.28 46 .112c-5.391-.085-18.228-.205-26.443 0zM14.422 14.234C3.332 14.234-.468 24.76.045 31.948c3.594-6.418 7.617-7.53 9.243-7.445h6.675c5.956 0 11.039-6.846 12.836-10.27H14.422z"
        fill="currentColor"
      />
    ),
    viewBox: "0 0 46 32",
  },
  // ... Add other custom icons here
};

const theme = extendTheme({
  fonts: {
    body: `Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
  },
  fontWeights: {
    normal: 400,
    medium: 600,
    bold: 800,
  },
  icons: {
    // Do not spread `chakraTheme.icons` here, it's unnecessary
    ...customIcons,
  },
  components: {
    // 2. Customize the ModalOverlay globally
    ModalOverlay: {
      // You can add base styles here
      baseStyle: {
        zIndex: 1280, // Apply the zIndex to all ModalOverlays
      },
    },
  },
  breakpoints: {
    sm: "480px",
    smm: "624px",
    md: "768",
    mdd: "880px",
    lg: "992",
    lgg: "1350px",
    xl: "1280px",
    xxl: "1600px",
  },
  colors: {
    PTOrange: {
      100: "#FF86001A",
      200: "#FF860033",
      300: "#FF86004D",
      400: "#FF860066",
      500: "#FF860080",
      600: "#FF860099",
      700: "#FF8600B3",
      800: "#FF8600CC",
      900: "#FF8600E6",
      1000: "#FF8600FF",
    },
    PTRed: {
      100: "#D9134C1A",
      200: "#D9134C33",
      300: "#D9134C4D",
      400: "#D9134C66",
      500: "#D9134C80",
      600: "#D9134C99",
      700: "#D9134CB3",
      800: "#D9134CCC",
      900: "#D9134CE6",
      1000: "#D9134CFF",
    },
  },
});

export default theme;
