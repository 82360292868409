import {
  Button,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
  Text,
  Flex,
  Tooltip,
  Box,
  MenuButton,
  MenuList,
  MenuItem,
  Menu,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import EmptyState from "../Global/EmptyState";
import { WikiPage } from "../../Models/WikiPage";
import {
  deleteSubPage,
  deleteWikiPage,
  setWikiPages,
} from "../../Features/WikiPages/wikiSlice";
import {
  deleteWikiPageById,
  deleteSubPageById,
  getAllWikiPages,
} from "../../Services/CommandCenterService";
import { ChevronDownIcon, EditIcon } from "@chakra-ui/icons";
import { useAppDispatch } from "../../hooks";
import DeleteAlertDialog from "../Global/DeleteAlertDialog";
import { formatDate } from "../Global/functions";
import EditWikiPageModal from "./EditWikiPageModal";
import { contentStyle } from "../../Styles/TinyEditorLayout";
import { SubPage } from "../../Models/SubPage";
import AddSubPageModal from "./AddSubPageModal";
import EditSubPageModal from "./EditSubPageModal";

interface IWikiTabsProps {
  wikiPages: WikiPage[];
  subPages: SubPage[];
}

interface PageContent {
  id: number | undefined;
  title: string;
  content: string;
  createdBy: string | null;
  createdAt: string | null;
  modifiedBy: string | null;
  updatedAt: string | null;
  type: string;
}

const WikiTabs: React.FC<IWikiTabsProps> = ({ wikiPages, subPages }) => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const [wikiPage, setWikiPage] = useState<WikiPage>();
  const [subPage, setSubPage] = useState<SubPage>();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  let defaultContent: PageContent;

  defaultContent =
    wikiPages.length > 0
      ? {
          id: wikiPages[0].id!,
          title: wikiPages[0].title,
          content: wikiPages[0].content || "",
          createdBy: wikiPages[0].createdBy || "",
          createdAt: wikiPages[0].createdAt || "",
          modifiedBy: wikiPages[0].modifiedBy || "",
          updatedAt: wikiPages[0].updatedAt || "",
          type: "wiki",
        }
      : {
          id: 0,
          title: "",
          content: "",
          createdBy: null,
          createdAt: null,
          modifiedBy: null,
          updatedAt: null,
          type: "",
        };

  const [selectedContent2, setSelectedContent2] =
    useState<PageContent>(defaultContent);

  const handleTabChange2 = (index) => {
    setTabIndex(index);
    setSelectedContent2(defaultContent); // Reset selected content
  };

  const handleEdit = (selectedPage: PageContent, tabIndex: number) => {
    if (selectedPage.type === "wiki") {
      setWikiPage({
        id: selectedPage.id,
        title: selectedPage.title,
        content: selectedPage.content,
        createdBy: selectedPage.createdBy || "unknown",
        createdAt: selectedPage.createdAt || "",
        modifiedBy: selectedPage.modifiedBy || "unknown",
        updatedAt: selectedPage.updatedAt || "",
      });
      setIsEditOpen(true);
    } else if (selectedPage.type === "sub") {
      setSubPage({
        id: selectedPage.id,
        label: selectedPage.title,
        content: selectedPage.content,
        createdBy: selectedPage.createdBy || "unknown",
        createdAt: selectedPage.createdAt || "",
        modifiedBy: selectedPage.modifiedBy || "unknown",
        updatedAt: selectedPage.updatedAt || "",
        wikiPageId: wikiPages[tabIndex].id!,
      });
      setIsEditOpen(true);
    } else {
      console.error("Page type is undefined");
    }
  };

  //uses the id and type from selectedContent to delete the page
  const handleDelete = async (id: number, type: string, tabIndex: number) => {
    if (id) {
      if (type === "wiki") {
        await deleteWikiPageById(id);
        // Remove the page from the state
        dispatch(deleteWikiPage(id));
      } else if (type === "sub") {
        await deleteSubPageById(id);
        // Remove the page from the state
        dispatch(deleteSubPage(id));
      }
    } else {
      console.error("ID is undefined");
    }

    // When deleting a page, go back to the first tab
    setTabIndex(0);
    // Reset the selected content
    setSelectedContent2(defaultContent);

    toast({
      title: "Success!",
      description: `Page deleted`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  useEffect(() => {
    (async () => {
      const loadData = async (): Promise<WikiPage[]> => {
        const wikiPagesResult: WikiPage[] = await getAllWikiPages();
        setWikiPages(wikiPagesResult);
        return wikiPagesResult;
      };
      const _wikiPages = await loadData();

      defaultContent =
        // eslint-disable-next-line
        _wikiPages.length > 0
          ? {
              id: _wikiPages[0].id!,
              title: _wikiPages[0].title,
              content: _wikiPages[0].content || "",
              createdBy: _wikiPages[0].createdBy || "",
              createdAt: _wikiPages[0].createdAt || "",
              modifiedBy: _wikiPages[0].modifiedBy || "",
              updatedAt: _wikiPages[0].updatedAt || "",
              type: "wiki",
            }
          : {
              id: 0,
              title: "",
              content: "",
              createdBy: null,
              createdAt: null,
              modifiedBy: null,
              updatedAt: null,
              type: "",
            };
      setSelectedContent2(defaultContent);
    })();
  }, []);

  return (
    <>
      {wikiPages.length ? (
        <>
          <Tabs
            variant="line"
            index={tabIndex}
            onChange={() => handleTabChange2}
          >
            {subPage && (
              <EditSubPageModal
                isOpen={isEditOpen}
                subPage={subPage}
                onClose={() => {
                  setSubPage(undefined);
                  setIsEditOpen(false);
                }}
                onSave={() => {}}
              />
            )}
            {wikiPage && (
              <EditWikiPageModal
                isOpen={isEditOpen}
                wikiPage={wikiPage}
                onClose={() => {
                  setWikiPage(undefined);
                  setIsEditOpen(false);
                }}
                onSave={() => {}}
              />
            )}
            <TabList mb="1em">
              {wikiPages.map((wikiPage, index) => (
                <Tab _selected={{ borderColor: "#b1b7dd" }} key={wikiPage.id}>
                  {/*If it is the first tab, add the emoji*/}
                  {index === 0 ? <p>📖</p> : null}
                  {subPages.filter(
                    (subPage) => subPage.wikiPageId === wikiPage.id
                  ).length > 0 ? (
                    // Render the menu if there are subpages
                    <Menu isLazy>
                      <MenuButton
                        as={Button}
                        //onMouseEnter={() => setShowSubMenu(true)}
                        //onMouseLeave={() => setShowSubMenu(false)}
                        rightIcon={<ChevronDownIcon />}
                        _hover={{ bg: "transparent", color: "blue.400" }}
                        _active={{ bg: "transparent", color: "currentColor" }}
                        fontWeight={"normal"}
                      >
                        {wikiPage.title}
                      </MenuButton>
                      <MenuList
                        ml={index === 0 ? -10 : -4} //display={showSubMenu ? 'block' : 'none'}
                      >
                        <MenuItem
                          onClick={() =>
                            setSelectedContent2({
                              id: wikiPage.id!,
                              title: wikiPage.title,
                              content: wikiPage.content || "",
                              createdBy: wikiPage.createdBy || "can't find",
                              createdAt: wikiPage.createdAt || "",
                              modifiedBy: wikiPage.modifiedBy || "",
                              updatedAt: wikiPage.updatedAt || "",
                              type: "wiki",
                            })
                          }
                        >
                          {wikiPage.title}
                        </MenuItem>
                        {subPages
                          .filter(
                            (subPage) => subPage.wikiPageId === wikiPage.id
                          )
                          .map((subPage) => (
                            <MenuItem
                              ml={4}
                              bg={"transparent"}
                              key={subPage.id}
                              onClick={() =>
                                setSelectedContent2({
                                  id: subPage.id!,
                                  title: subPage.label,
                                  content: subPage.content || "",
                                  createdBy: subPage.createdBy || "Can't find",
                                  createdAt: subPage.createdAt || "",
                                  modifiedBy: subPage.modifiedBy || "",
                                  updatedAt: subPage.updatedAt || "",
                                  type: "sub",
                                })
                              }
                            >
                              {subPage.label}
                            </MenuItem>
                          ))}
                      </MenuList>
                    </Menu>
                  ) : (
                    // Just display the title if there are no subpages
                    <p
                      onClick={() =>
                        setSelectedContent2({
                          id: wikiPage.id!,
                          title: wikiPage.title,
                          content: wikiPage.content || "",
                          createdBy: wikiPage.createdBy || "can't find",
                          createdAt: wikiPage.createdAt || "",
                          modifiedBy: wikiPage.modifiedBy || "",
                          updatedAt: wikiPage.updatedAt || "",
                          type: "wiki",
                        })
                      }
                    >
                      {wikiPage.title}
                    </p>
                  )}
                </Tab>
              ))}
            </TabList>
            <TabPanels bgColor="white" mt="-5">
              <TabPanel key={selectedContent2!.id} textAlign={"left"}>
                <Flex justifyContent="space-between" width="100%">
                  <Stack direction="row">
                    <Text
                      textAlign={"left"}
                      fontStyle={"italic"}
                      fontSize={"2xs"}
                    >
                      Created by: {selectedContent2!.createdBy ?? "Unknown"}
                    </Text>
                    <Text
                      textAlign={"left"}
                      fontStyle={"italic"}
                      fontSize={"2xs"}
                    >
                      {formatDate(selectedContent2!.createdAt!)}
                    </Text>
                  </Stack>
                  <Stack direction="row" align={"center"}>
                    {selectedContent2?.modifiedBy && (
                      <Tooltip
                        hasArrow
                        arrowSize={15}
                        mb={2}
                        closeDelay={250}
                        label={`${
                          selectedContent2.modifiedBy ?? "Unknown"
                        } at ${formatDate(selectedContent2.updatedAt!)}`}
                        bg="gray.200"
                        color="gray.700"
                        placement="top"
                        sx={{
                          fontSize: "xs",
                          fontWeight: "light",
                          p: 2,
                          borderRadius: "sm",
                        }}
                      >
                        <Text
                          textAlign={"left"}
                          fontStyle={"italic"}
                          fontSize={"2xs"}
                        >
                          Modified
                        </Text>
                      </Tooltip>
                    )}

                    <Button
                      onClick={() => handleEdit(selectedContent2, tabIndex)}
                      size="xs"
                      backgroundColor="gray.600"
                      color="white"
                      fontWeight="medium"
                      _hover={{ bg: "gray.700" }}
                      _active={{
                        bg: "gray.800",
                        transform: "scale(0.95)",
                      }}
                      leftIcon={<EditIcon />}
                    >
                      Edit
                    </Button>
                    {selectedContent2.type === "wiki" && (
                      <AddSubPageModal wikiPageId={selectedContent2.id}>
                        Add subpage
                      </AddSubPageModal>
                    )}

                    <DeleteAlertDialog
                      item="Page"
                      onDelete={() =>
                        handleDelete(
                          selectedContent2.id!,
                          selectedContent2.type,
                          tabIndex
                        )
                      }
                      buttonColor="gray"
                      isButton={true}
                    />
                  </Stack>
                </Flex>
                <Box
                  dangerouslySetInnerHTML={{
                    __html:
                      selectedContent2 !== undefined
                        ? selectedContent2?.content ||
                          "This page has no content"
                        : "",
                  }}
                  sx={contentStyle}
                  fontSize={"sm"}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      ) : (
        <>
          <Tabs>
            <TabList mb="1em">
              <Tab _selected={{ borderColor: "#b1b7dd" }}>📖</Tab>
            </TabList>
            <TabPanels bgColor="white" mt="-5">
              <EmptyState
                heading="📄 No Wiki Pages 📄"
                text="Add a page to get started"
              />
            </TabPanels>
          </Tabs>
        </>
      )}
    </>
  );
};

export default WikiTabs;
