import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Action } from "../../Models/Action";
import { Tenant } from "../../Models/Tenant";
import { Alert } from "../../Models/Alert";
import { CustomerInfo } from "../../Models/CustomerInfo";

import { RootState } from "../../store";
import { Assignment } from "../../Models/Assignment";

interface ActiveTenanStateSlice {
  tenant: Tenant;
  actions: Action[];
  historicalActions: Action[];
  alerts: Alert[];
  customerInfos: CustomerInfo[];
}

interface TenantState {
  allTenants: Tenant[];
  activeTenant: ActiveTenanStateSlice | null;
  allAssignments: Assignment[];
  allActions: Action[];
}

const initialState: TenantState = {
  allTenants: [],
  activeTenant: null,
  allAssignments: [],
  allActions: [],
};

export const tenantSlice = createSlice({
  name: "tenants",
  initialState,
  reducers: {
    setSelectedTenant: (
      state,
      action: PayloadAction<{
        tenant: Tenant;
        actions: Action[];
        historicalActions: Action[];
        alerts: Alert[];
        customerInfos: CustomerInfo[];
      }>
    ) => {
      state.activeTenant = {
        tenant: action.payload.tenant,
        actions: action.payload.actions,
        historicalActions: action.payload.historicalActions,
        alerts: action.payload.alerts,
        customerInfos: action.payload.customerInfos,
      };
    },
    deleteTenantAction: (state, action: PayloadAction<number>) => {
      // Delete the action from the state
      const actions = state.activeTenant!.actions;
      const newActions = actions.filter((a) => a.id !== action.payload);
      state.activeTenant!.actions = [...newActions];
    },
    addTenantAction: (state, action: PayloadAction<Action>) => {
      state.activeTenant = {
        tenant: { ...state.activeTenant!.tenant },
        actions: [...state.activeTenant!.actions, action.payload],
        historicalActions: [
          ...state.activeTenant!.historicalActions,
          action.payload,
        ],
        alerts: [...state.activeTenant!.alerts],
        customerInfos: [...state.activeTenant!.customerInfos],
      };
    },
    editTenantAction: (state, action: PayloadAction<Action>) => {
      state.activeTenant = {
        tenant: { ...state.activeTenant!.tenant },
        actions: state.activeTenant!.actions.map((a) =>
          a.id === action.payload.id ? action.payload : a
        ),
        historicalActions: state.activeTenant!.historicalActions.map((a) =>
          a.id === action.payload.id ? action.payload : a
        ),
        alerts: [...state.activeTenant!.alerts],
        customerInfos: [...state.activeTenant!.customerInfos],
      };
    },
    setTenants: (state, action: PayloadAction<Array<Tenant>>) => {
      state.allTenants = action.payload;
    },
    setActions: (state, action: PayloadAction<Array<Action>>) => {
      state.allActions = action.payload;
    },
    deleteTenant: (state, action: PayloadAction<number>) => {
      state.allTenants = state.allTenants.filter(
        (t) => t.id! !== action.payload
      );
    },
    addTenant: (state, action: PayloadAction<Tenant>) => {
      state.allTenants = [...state.allTenants, action.payload];
    },
    editTenant: (state, action: PayloadAction<Tenant>) => {
      state.activeTenant!.tenant = { ...action.payload };
    },
    addCustomerInfo: (state, action: PayloadAction<CustomerInfo>) => {
      state.activeTenant!.customerInfos = [
        ...state.activeTenant!.customerInfos,
        action.payload,
      ];
    },
    editCustomerInfo: (state, action: PayloadAction<CustomerInfo>) => {
      state.activeTenant!.customerInfos = state.activeTenant!.customerInfos.map(
        (t) =>
          t.azureTenantId === action.payload.azureTenantId ? action.payload : t
      );
    },

    setAssignments: (state, action: PayloadAction<Array<Assignment>>) => {
      state.allAssignments = action.payload;
    },
    deleteAssignment: (state, action: PayloadAction<number>) => {
      state.allAssignments = state.allAssignments.filter(
        (t) => t.id! !== action.payload
      );
    },
    addAssignment: (state, action: PayloadAction<Assignment>) => {
      state.allAssignments = [...state.allAssignments, action.payload];
    },
    editAssignment: (state, action: PayloadAction<Assignment>) => {
      state.allAssignments = state.allAssignments.map((t) =>
        t.id === action.payload.id ? action.payload : t
      );
    },
  },
});

export const {
  setTenants,
  setActions,
  setSelectedTenant,
  addTenantAction,
  editTenantAction,
  deleteTenantAction,
  /*addComment,
  editComment,
  deleteComment,
  setComments,*/
  addTenant,
  editTenant,
  deleteTenant,
  addCustomerInfo,
  editCustomerInfo,
  setAssignments,
  deleteAssignment,
  addAssignment,
  editAssignment,
} = tenantSlice.actions;

export const allTenants = (state: RootState) => state.tenant.allTenants;
export const allActions = (state: RootState) => state.tenant.allActions;
export const activeTenant = (state: RootState) => state.tenant.activeTenant;
export const activeTenantActions = (state: RootState) =>
  state.tenant.activeTenant?.actions;
export const activeTenantHistoricalActions = (state: RootState) =>
  state.tenant.activeTenant?.historicalActions;
/* export const internalActionComments = (state: RootState) =>
  state.tenant.activeTenant?.comments;
  export const externalActionComments = (state: RootState) =>
  state.tenant.activeTenant?.externalComments;*/
export const activeTenantAlerts = (state: RootState) =>
  state.tenant.activeTenant?.alerts;
export const activeTenantCustomerInfos = (state: RootState) =>
  state.tenant.activeTenant?.customerInfos;
export const allAssignments = (state: RootState) => state.tenant.allAssignments;
export default tenantSlice.reducer;
