import {
  Box,
  Text,
  Heading,
  Stack,
  Flex,
  Card,
  CardHeader,
  Avatar,
  Icon,
  Switch,
  Button,
  Spacer,
  Divider,
  useToast,
} from "@chakra-ui/react";
import * as React from "react";
import "../../Styles/editor.module.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AddActionFromTemplate from "./AddActionFromTemplate";
import { Category } from "../../Models/Category";
import { Comment } from "../../Models/Comment";
import { Lock as LockIcon, Groups as GroupsIcon } from "@mui/icons-material";
import { formatDate2 } from "../Global/functions";
import CommentEditor from "../Global/CommentEditor";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { activeTenant } from "../../Features/Tenants/tenantSlice";
import { msalInstance } from "../../AppAuth";
import {
  createCommentOnAction,
  deleteCommentById,
} from "../../Services/CommandCenterService";
import {
  addActionComment,
  allStatuses,
  deleteComment,
} from "../../Features/Config/configSlice";
import DeleteAlertDialog from "../Global/DeleteAlertDialog";
import { statusColor } from "../Global/functions";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  shortText: string;
  description: string;
  category: Category;
  isItem: boolean;
  updateFormData: () => void;
  comments: Comment[];
  actionIdd?: number;
  statusId?: number;
};

interface FormValues {
  azureTenantId: string;
  commentText: string;
  createdBy: string;
  isExternal: boolean;
  actionId: number;
}

const ReviewPopup: React.FC<Props> = ({
  shortText,
  description,
  category,
  isItem,
  updateFormData,
  comments,
  actionIdd,
  statusId,
}) => {
  const formattedHtmlContent1 = shortText.replace(/\n/g, "<br/>");
  const formattedHtmlContent2 = description.replace(/\n/g, "<br/>");
  const [isPublic, setIsPublic] = React.useState(false);
  const dispatch = useAppDispatch();
  const activeTenantSlice = useAppSelector(activeTenant);
  const tenant = activeTenantSlice?.tenant;
  const activeAccount = msalInstance.getActiveAccount();
  const toast = useToast();
  const statuses = useAppSelector(allStatuses);
  const statusName = statuses.find((s) => s.id === statusId)?.name;

  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting },
    control,
  } = useForm<FormValues>();

  const onCreateComment = async ({ commentText, isExternal }) => {
    const newComment: Comment = {
      azureTenantId: tenant!.azureTenantId,
      commentText: commentText,
      createdBy: activeAccount?.name || "No Author Found",
      isExternal: isExternal,
      actionId: actionIdd!,
    };

    const result = await createCommentOnAction(newComment);

    const res = await result.json();

    // Update the UI with the newly created action in the database
    dispatch(addActionComment(res));
    reset();
  };

  const handleDelete = async (id: number) => {
    await deleteCommentById(id);

    dispatch(deleteComment(id));

    toast({
      title: "Success!",
      description: `Comment deleted`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <>
      <Divider orientation="vertical" bg="gray.400" my={2} />
      <Stack w="100%" h="100%" spacing={2} direction="row">
        {/*Box1*/}
        <Box
          h="100%"
          mt="1"
          as="h4"
          lineHeight="10"
          w={isItem ? "100%" : "60%"}
        >
          <Box overflowY="auto" maxH="97%" minW="90%" mt={3} mx={2}>
            <Flex alignItems="center" justifyContent="left">
              <Heading mx={4} mt={0} textAlign="left" fontSize="2xl">
                <div
                  dangerouslySetInnerHTML={{ __html: formattedHtmlContent1 }}
                />
              </Heading>

              {!isItem && (
                <Box justifyContent={"bottom"}>
                  <Box justifyContent={"bottom"}>
                    <Text
                      fontSize={"xs"}
                      color={"white"}
                      fontWeight={16}
                      bg={statusId ? statusColor(statusId) : "transparent"}
                      borderRadius={15}
                      lineHeight={1.5}
                      px={2}
                    >
                      {statusName}
                    </Text>
                  </Box>
                </Box>
              )}
              <Box />
              {isItem && (
                <AddActionFromTemplate
                  category={category}
                  shortText={shortText}
                  description={description}
                  onActionCreated={updateFormData}
                >
                  + Activate
                </AddActionFromTemplate>
              )}
            </Flex>
            <div className="ol">
              <Text
                mx={4}
                my={4}
                textAlign="left"
                lineHeight={1.5}
                fontSize="md"
              >
                <div
                  className="rdw-editor-main"
                  dangerouslySetInnerHTML={{ __html: formattedHtmlContent2 }}
                />
              </Text>
            </div>
          </Box>
        </Box>

        {!isItem && actionIdd && (
          <>
            <Divider orientation="vertical" bg="gray.400" my={2} />
            {/*Box2*/}
            <Box h="100%" mt="1" as="h4" lineHeight="10" w="40%">
              <Box overflowY="auto" maxH="97%" minW="90%" mt={3} mx={2}>
                <Heading mx={4} mt={0} textAlign="left" fontSize="2xl">
                  Comments
                </Heading>

                {/*FORM*/}
                <Box
                  flex="1"
                  as="form"
                  onSubmit={handleSubmit(onCreateComment)}
                  mx={4}
                  my={4}
                >
                  <Controller
                    name="commentText"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <CommentEditor
                        content={field.value}
                        onContentChange={field.onChange} // onChange updates the form's state
                      />
                    )}
                  />
                  <Stack
                    direction="row"
                    alignItems={"top"}
                    justifyContent={"left"}
                    mt={2}
                  >
                    <Stack gap={0} textAlign={"left"} ml={2}>
                      {/* Icon and Switch */}
                      <Stack direction={"row"}>
                        <Icon
                          as={isPublic ? GroupsIcon : LockIcon}
                          boxSize={"4"}
                        />
                        <Switch
                          id="isExternal"
                          {...register("isExternal")}
                          colorScheme="orange"
                          size="sm"
                          defaultChecked={isPublic}
                          onChange={(e) => setIsPublic(e.target.checked)}
                        />
                      </Stack>
                      <Text
                        fontSize={"xs"}
                        color={"gray.600"}
                        lineHeight={1.5}
                        ml={-0.5}
                      >
                        <i>
                          {isPublic
                            ? "Will be shown to customers"
                            : "Personal note"}
                        </i>
                      </Text>
                    </Stack>
                    <Spacer />
                    <Button
                      type="submit"
                      colorScheme="orange"
                      size="sm"
                      isLoading={isSubmitting}
                    >
                      Add comment
                    </Button>
                  </Stack>
                </Box>
                {/*END OF FORM*/}

                <Box flex="1" />
                {!isItem &&
                  (comments.length ? (
                    <Text
                      mx={4}
                      my={4}
                      textAlign="left"
                      lineHeight={1.5}
                      fontSize="md"
                    >
                      {comments.map((c) => (
                        <Card key={c.id} bgColor={"gray.200"} mt={2}>
                          <CardHeader>
                            <Flex
                              flex="1"
                              gap="4"
                              alignItems="center"
                              flexWrap="wrap"
                              position={"relative"}
                            >
                              <Avatar src={""} bgColor="gray.300" />

                              <Box flex={1}>
                                <Stack direction="row" alignItems={"center"}>
                                  <Heading size="sm">{c.createdBy}</Heading>
                                  <Icon
                                    as={c.isExternal ? GroupsIcon : LockIcon}
                                    boxSize={"4"}
                                  />
                                </Stack>
                                <Text fontSize={"sm"}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: c.commentText,
                                    }}
                                  />
                                </Text>
                                <Text fontSize={"xs"} color={"gray.600"}>
                                  {" "}
                                  {c.createdAt
                                    ? formatDate2(c.createdAt)
                                    : null}
                                </Text>
                              </Box>
                              <Box position={"absolute"} top={0} right={0}>
                                <DeleteAlertDialog
                                  onDelete={() => handleDelete(c.id!)}
                                  item="Comment"
                                  size="15px"
                                  isIcon={true}
                                />
                              </Box>
                            </Flex>
                          </CardHeader>
                        </Card>
                      ))}
                    </Text>
                  ) : (
                    <Text
                      color={"gray.400"}
                      fontStyle={"italic"}
                      fontSize={12}
                      mt={8}
                    >
                      No comments
                    </Text>
                  ))}
              </Box>
            </Box>
          </>
        )}
      </Stack>
    </>
  );
};

export { ReviewPopup };
