import {
  Flex,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React from "react";
import { Category } from "../../Models/Category";
import AddCategoryModal from "./AddCategoryModal";
import CategoryTable from "./CategoryTable";
import TemplateActionTable from "./TemplateActionTable";
import EmptyState from "../Global/EmptyState";
import { TemplateAction } from "../../Models/TemplateAction";
import AddTemplateActionModal from "./AddTemplateActionModal";
import AssignmentsTable from "./AssignmentsTable";
import { Tenant } from "../../Models/Tenant";
import { Assignment } from "../../Models/Assignment";

interface IConfigurationTabsProps {
  categories: Category[];
  templateActions: TemplateAction[];
  tenants: Tenant[];
  assignments: Assignment[];
}

const ConfigurationTabs: React.FC<IConfigurationTabsProps> = ({
  categories,
  templateActions,
  tenants,
  assignments,
}) => {
  return (
    <Tabs variant="line">
      <TabList mb="1em">
        <Tab _selected={{ borderColor: "#b1b7dd" }}>Categories</Tab>
        <Tab _selected={{ borderColor: "#b1b7dd" }}>Template Actions</Tab>
        <Tab _selected={{ borderColor: "#b1b7dd" }}>Assignments</Tab>
      </TabList>
      <TabPanels bgColor="white" mt="-5">
        <TabPanel>
          <Flex mb="1em" justifyContent="space-between">
            <Stack direction="row" spacing={3} minW="50%">
              <Input
                mt="1px"
                placeholder="Filter on title"
                size="sm"
                rounded="sm"
                maxW="50%"
                variant="outline"
              />
              {/* <Select
                  placeholder="Select category"
                  size="sm"
                  rounded="sm"
                  width="auto"
                >
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </Select> */}
            </Stack>
            <AddCategoryModal>+ Add Category</AddCategoryModal>
          </Flex>
          {categories.length ? (
            <CategoryTable items={categories} />
          ) : (
            <EmptyState
              heading="You haven't added any data"
              text="Add a category to get started"
            />
          )}
        </TabPanel>
        <TabPanel>
          <Flex mb="1em" justifyContent="space-between">
            <Stack direction="row" spacing={3} minW="50%">
              <Input
                mt="1px"
                placeholder="Filter on title"
                size="sm"
                rounded="sm"
                maxW="50%"
                variant="outline"
              />
              {/* <Select
                  placeholder="Select category"
                  size="sm"
                  rounded="sm"
                  width="auto"
                >
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </Select> */}
            </Stack>
            <AddTemplateActionModal>
              + Add Template Action
            </AddTemplateActionModal>
          </Flex>
          {templateActions.length ? (
            <TemplateActionTable items={templateActions} />
          ) : (
            <EmptyState
              heading="You haven't added any data"
              text="Add a template to get started"
            />
          )}
        </TabPanel>
        <TabPanel>
          <AssignmentsTable tenants={tenants} assignments={assignments} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default ConfigurationTabs;
