import { IPublicClientApplication } from "@azure/msal-browser";
import { Box, Flex, Grid } from "@chakra-ui/react";
import * as React from "react";
import { useEffect } from "react";
import { setCategories, setStatuses } from "../Features/Config/configSlice";
import { useAppDispatch } from "../hooks";
import {
  getAllActions,
  getAllCategories,
  getAllStatuses,
  getAllWikiPages,
} from "../Services/CommandCenterService";
import NavBar from "../Pages/Global/NavBar";
import { setWikiPages } from "../Features/WikiPages/wikiSlice";
import { setActions } from "../Features/Tenants/tenantSlice";

interface IDashboardShellProps {
  instance?: IPublicClientApplication;
  children: React.ReactNode;
}

const PageLayout: React.FC<IDashboardShellProps> = ({ children, instance }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const loadAppConfig = async () => {
      const categories = await getAllCategories();
      const statuses = await getAllStatuses();
      const wikiPages = await getAllWikiPages();
      const actionss = await getAllActions();

      dispatch(setActions(actionss));
      dispatch(setCategories(categories));
      dispatch(setStatuses(statuses));
      dispatch(setWikiPages(wikiPages));
    };

    loadAppConfig();
  }, [dispatch]);

  return (
    <Box textAlign="center" fontSize="xl">
      <Grid minH="100vh">
        <Box backgroundColor="gray.100" h="100vh">
          <NavBar />
          <Flex margin="0 auto" direction="column" maxW="1900" px={8}>
            {children}
          </Flex>
        </Box>
      </Grid>
    </Box>
  );
};

export default PageLayout;
