import React from "react";
import { Editor as TinyMCEEditor } from "@tinymce/tinymce-react";
import ImageGallery from "./imageGallery";
import { Button, useClipboard, Text, Stack } from "@chakra-ui/react";
import FileUpload from "./FileUpload";

/**
 * NB! When used inside a ChakraUI modal, please add these to the component.
 * It solves the link-plugin issue that makes the form not interactable.
 * <Modal
 * blockScrollOnMount={false} trapFocus={false} returnFocusOnClose={false} />
 */

interface TinyEditorProps {
  content: string;
  onContentChange: (content: string) => void;
  editorTitle: string;
}

export const sleep = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const TinyEditor: React.FC<TinyEditorProps> = ({
  content,
  onContentChange,
  editorTitle,
  //imageUrl,
  //editor,
  //setEditorInstance,
}) => {
  const [selectedImageUrl, setSelectedImageUrl] = React.useState("");
  const { onCopy } = useClipboard(selectedImageUrl);

  //prints only the filename of the url
  const getFileNameFromUrl = (url) => {
    return url.split("/").pop();
  };
  return (
    <React.Fragment>
      <>
        <ImageGallery
          onImageChanged={(image) => {
            setSelectedImageUrl(image);
          }}
        />
        <Stack direction={"row"} my={2}>
          <FileUpload onlyButton={true} />
          <Button onClick={onCopy} colorScheme="orange" fontStyle={"unset"}>
            Copy {getFileNameFromUrl(selectedImageUrl)}
          </Button>
        </Stack>
        <Text fontWeight={"medium"}>{editorTitle}</Text>
        <TinyMCEEditor
          apiKey="6jdsnk9zbj86ga29pbk02e3rermf0wersu1ltm0umkflyoju" //Personal API key, made on user Anna Linnerud can be changed to another user
          //ref={editor}
          value={content}
          onEditorChange={(newContent) => {
            onContentChange(newContent);
          }}
          init={{
            //editor configurations
            height: 500,
            menubar: "insert edit format tools",
            removed_menuitems: "code",
            language: "en",
            plugins: [
              "accordion",
              "image",
              "link",
              "autolink",
              "charmap",
              "code",
              "fullscreen",
              "insertdatetime",
              "lists",
              "preview",
              "table",
              "visualblocks",
              "wordcount",
              "advlist",
              "codesample",
            ],
            toolbar:
              "undo redo removeformat | link image accordion | styles | " +
              "bold italic underline strikethrough | forecolor fontsize" +
              "| bullist numlist indent outdent | align fullscreen",

            statusbar: true,
            link_assume_external_targets: true,
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:12px }",
            setup: function (editor) {
              //Adding a custom button to the toolbar
              editor.ui.registry.addButton("gallery", {
                text: "My gallery",
                icon: "gallery",
                onAction: function (_) {
                  editor.insertContent(
                    `<img src="${selectedImageUrl}" alt="Alt for image"/>`
                  );
                },
              });
            },
          }}
        />
      </>
    </React.Fragment>
  );
};

export default TinyEditor;
