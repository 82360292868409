import { EventType, PublicClientApplication } from "@azure/msal-browser";
import React, { useEffect, useState } from "react";
import { hasAzAdminRole, msalConfig } from "./authConfig";
import { App } from "./App";

export const msalInstance = new PublicClientApplication(msalConfig as any);

export const AppAuth = () => {
  const [loginError, setLoginError] = useState("");

  useEffect(() => {
    (async () => {
      await msalInstance.initialize();

      // if (accounts.length > 0) {
      //   const accountClaims = accounts[0]?.idTokenClaims;
      //   if (accountClaims !== undefined && !isAzAdmin(accountClaims["roles"])) {
      //     loginError =
      //       "The sso user does not have the required AzAdmin app role";
      //   } else {
      //     //loginError = "";
      //     setLoginError("");
      //     msalInstance.setActiveAccount(accounts[0]);
      //     setLoginComplete(true);
      //   }
      // }

      //event: any => some bad typings
      msalInstance.addEventCallback((event: any) => {
        if (
          event.eventType === EventType.LOGIN_SUCCESS &&
          event.payload.account
        ) {
          const account = event.payload.account;

          if (!hasAzAdminRole(event.payload.idToken)) {
            setLoginError(
              "The user does not have the required CCuser app role"
            );
            msalInstance.logoutRedirect();
          } else {
            //loginError = "";
            setLoginError("");
            msalInstance.setActiveAccount(account);
          }
        }

        if (event.eventType === EventType.LOGIN_FAILURE) {
          console.log(JSON.stringify(event));
        }
      });
    })();
  }, []);

  if (msalInstance) {
    return <App instance={msalInstance} loginError={loginError} />;
  } else {
    return <div>Loading...</div>;
  }
};

export default AppAuth;
