import React, { useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Icon,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import ClearIcon from "@mui/icons-material/Clear";

interface IDeleteAlertDialogProps {
  item?: string;
  onDelete: () => void;
  buttonColor?: string;
  size?: string;
  isButton?: boolean;
  isIcon?: boolean;
  isCross?: boolean;
}

const DeleteAlertDialog: React.FC<IDeleteAlertDialogProps> = ({
  item = "",
  onDelete,
  buttonColor = "red",
  size = "xs",
  isButton = false,
  isIcon = false,
  isCross = false,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      {isButton && (
        <>
          <Button
            onClick={onOpen}
            size={size}
            backgroundColor={buttonColor + ".600"}
            color="white"
            fontWeight="medium"
            _hover={{ bg: buttonColor + ".700" }}
            _active={{
              bg: buttonColor + ".800",
              transform: "scale(0.95)",
            }}
            leftIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </>
      )}
      {isIcon && (
        <IconButton
          aria-label="delete item"
          icon={<DeleteIcon />}
          bg="transparent"
          onClick={onOpen}
          fontSize={isHovered ? size + 1 : size}
          p={0}
          mt={-5}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
      )}

      {isCross && (
        <IconButton
          aria-label="delete item"
          size={size}
          icon={<Icon as={ClearIcon} />}
          bg="transparent"
          onClick={onOpen}
          fontSize={isHovered ? size + 1 : size}
          p={0}
          mt={0}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          _hover={{ bg: "transparent" }}
          _active={{
            bg: "transparent",
            transform: "scale(0.95)",
          }}
        />
      )}

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete {item}
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={onDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default DeleteAlertDialog;
