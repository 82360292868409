import { configureStore } from "@reduxjs/toolkit";

import configReducer from "./Features/Config/configSlice";
import tenantReducer from "./Features/Tenants/tenantSlice";
import wikiReducer from "./Features/WikiPages/wikiSlice";

const store = configureStore({
  reducer: {
    config: configReducer,
    tenant: tenantReducer,
    wiki: wikiReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
