import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Switch,
  useDisclosure,
  useToast,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { allCategories, allStatuses } from "../../Features/Config/configSlice";
import {
  activeTenant,
  addTenantAction,
  allTenants,
} from "../../Features/Tenants/tenantSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Action } from "../../Models/Action";
import { createActionOnTenant } from "../../Services/CommandCenterService";

import { Category } from "../../Models/Category";
import TinyEditor from "../Global/TinyEditor";
import { msalInstance } from "../../AppAuth";

type Props = {
  category: Category;
  shortText: string;
  description: string;
  onActionCreated: () => void;
  children: React.ReactNode;
};

interface FormValues {
  description: string;
  handledByCustomer: boolean;
  assistanceRequired: boolean;
  estimate: number;
  azureTenantName: string;
  category: string;
  shortText: string;
  status: string;
  handledBy: string;
  isGlobal: boolean;
}

const AddActionFromTemplate: React.FC<Props> = ({
  children,
  category,
  shortText,
  description,
  onActionCreated,
}) => {
  const categories = useAppSelector(allCategories);
  const statuses = useAppSelector(allStatuses);
  const activeTenantSlice = useAppSelector(activeTenant);
  const dispatch = useAppDispatch();
  const [warning, setWarning] = React.useState(false);
  const tenants = useAppSelector(allTenants);

  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => {
      //reset the form when the modal is closed
      reset();
    },
  });
  const toast = useToast();

  const tenant = activeTenantSlice?.tenant;
  const defaultStatus = statuses.find((status) => status.id === 1);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
    control,
  } = useForm<FormValues>({
    defaultValues: {
      //statusId: defaultStatus?.id,
    },
  });

  const onCreateAction = async ({
    description,
    handledByCustomer,
    assistanceRequired,
    estimate,
    status,
    handledBy,
    isGlobal,
  }) => {
    if (isGlobal) {
      //Create action from template on all tenants
      await Promise.all(
        tenants.map(async (tenant) => {
          const newAction: Action = {
            tenantId: tenant?.id ? tenant.id : 0,
            azureTenantId: tenant!.azureTenantId,
            description: description,
            shortText: shortText,
            handledByCustomer: handledByCustomer,
            categoryId: category.id!,
            category: {
              name: category.name,
            }!,
            assistanceRequired: assistanceRequired,
            estimate: estimate,
            statusId: defaultStatus?.id!,
            status: {
              name: defaultStatus?.name!,
            }!,
            handledBy: handledBy,
            isGlobal: isGlobal,
          };
          const result = await createActionOnTenant(newAction);
          const res = await result.json();

          // Update the UI with the newly created action in the database
          dispatch(addTenantAction(res));
        })
      );

      toast({
        title: "Success!",
        description: `Action registered on all tenants`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } else {
      //Create action from template on current tenant
      const newAction: Action = {
        tenantId: tenant?.id ? tenant.id : 0,
        azureTenantId: tenant!.azureTenantId,
        description: description,
        shortText: shortText,
        handledByCustomer: handledByCustomer,
        categoryId: category.id!,
        category: {
          name: category.name,
        }!,
        assistanceRequired: assistanceRequired,
        estimate: estimate,
        statusId: defaultStatus?.id!,
        status: {
          name: defaultStatus?.name!,
        }!,
        handledBy: handledBy,
        isGlobal: isGlobal,
      };

      const result = await createActionOnTenant(newAction);
      const res = await result.json();

      // Update the UI with the newly created action in the database
      dispatch(addTenantAction(res));

      toast({
        title: "Success!",
        description: `Action registered on ${tenant?.azureTenantName}`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
    reset();

    onClose();
  };

  return (
    <>
      <Button
        onClick={onOpen}
        size="sm"
        backgroundColor="gray.900"
        color="white"
        fontWeight="medium"
        _hover={{ bg: "gray.700" }}
        _active={{
          bg: "gray.800",
          transform: "scale(0.95)",
        }}
        mr={4}
        alignSelf="end"
      >
        {children}
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="6xl"
        blockScrollOnMount={false}
        trapFocus={false}
        returnFocusOnClose={false}
      >
        <ModalOverlay style={{ zIndex: 1280 }} />
        <ModalContent as="form" onSubmit={handleSubmit(onCreateAction)}>
          {/* <ModalContent as="form"> */}
          <ModalHeader fontWeight="bold">Add Action from Template</ModalHeader>

          <ModalBody pb={4}>
            <Stack direction={"row"} spacing={2} align={"start"}>
              <FormControl w={"20%"}>
                <FormLabel htmlFor="azureTenantName" ml={1}>
                  Tenant
                </FormLabel>
                <Input
                  id="azureTenantName"
                  placeholder=""
                  value={tenant?.azureTenantName}
                  disabled={true}
                  {...register("azureTenantName")}
                />
              </FormControl>
              <FormControl isInvalid={!!errors.shortText} w={"60%"}>
                <FormLabel htmlFor="shortText" ml={1}>
                  Title
                </FormLabel>
                <Input
                  value={shortText}
                  id="shortText"
                  {...register("shortText")}
                  disabled={true}
                />
                <FormErrorMessage>
                  {errors.shortText?.type === "maxLength" && (
                    <span>Max 128 characters</span>
                  )}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.category} w={"20%"}>
                <FormLabel htmlFor="category" ml={1}>
                  Category
                </FormLabel>
                <Select
                  id="category"
                  defaultValue={category.id}
                  {...register("category")}
                  isDisabled={true}
                >
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <FormControl mt={4} isInvalid={!!errors.description}>
              <FormLabel>Image Gallery</FormLabel>
              <Controller
                name="description"
                control={control}
                defaultValue={description}
                render={({ field }) => (
                  <TinyEditor
                    content={field.value}
                    onContentChange={field.onChange} // onChange updates the form's state
                    editorTitle="Description"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.description &&
                typeof errors.description.message === "string"
                  ? errors.description.message
                  : undefined}
              </FormErrorMessage>
            </FormControl>
            <Stack direction={"row"} mt={4} spacing={2} align={"start"} mx={2}>
              <FormControl w={"50%"}>
                <FormLabel>Activate for all customers?</FormLabel>
                <Stack direction={"row"} align={"end"} spacing={4}>
                  <Switch
                    mt={2}
                    size={"lg"}
                    id="isGlobal"
                    {...register("isGlobal")}
                    colorScheme="orange"
                    defaultChecked={warning}
                    onChange={(e) => setWarning(e.target.checked)}
                  />
                  {warning && (
                    <Text
                      fontSize={"2xs"}
                      color={"gray.500"}
                      fontStyle={"italic"}
                    >
                      NB! Creates this action on all tenants.
                    </Text>
                  )}
                  {!warning && (
                    <Text
                      fontSize={"2xs"}
                      color={"gray.500"}
                      fontStyle={"italic"}
                    >
                      This action will only be created <br /> on the current
                      tenant.
                    </Text>
                  )}
                </Stack>
              </FormControl>

              <FormControl isInvalid={!!errors.estimate}>
                <FormLabel>Estimate</FormLabel>
                <Input
                  placeholder="Estimate in hours, e.g. 0.5 or 4."
                  id="estimate"
                  {...register("estimate", {
                    maxLength: 8,
                  })}
                />
                <FormErrorMessage>
                  {errors.estimate?.type === "maxLength" && (
                    <span>Max 8 characters</span>
                  )}
                </FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel>Assigned To</FormLabel>
                <Input
                  placeholder="Who is this action assigned to?"
                  id="handledBy"
                  {...register("handledBy", {
                    maxLength: 128,
                  })}
                />
              </FormControl>
            </Stack>
            {/*<FormControl mt={4} isInvalid={!!errors.status}>
                <FormLabel mt={4} htmlFor="status">
                  Category
                </FormLabel>
                <Select
                  id="status"
                  defaultValue={status.id}
                  {...register("status")}
                  isDisabled={true}
                >
                  {statuses.map((status) => (
                    <option key={status.id} value={status.id}>
                      {status.name}
                    </option>
                  ))}
                </Select>
                  </FormControl>*/}
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                reset();
                onClose();
              }}
              mr={3}
              fontWeight="medium"
            >
              Cancel
            </Button>
            <Button
              isLoading={isSubmitting}
              colorScheme="orange"
              fontWeight="medium"
              type="submit"
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddActionFromTemplate;
