import { CheckCircleIcon, WarningTwoIcon } from "@chakra-ui/icons";
import {
  Flex,
  Stack,
  Stat,
  StatHelpText,
  StatNumber,
  Text,
  useToast,
} from "@chakra-ui/react";
import { formatRelative, subDays } from "date-fns";
import groupBy from "lodash.groupby";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditTenantModal from "../Tenants/EditTenantModal";
import EmptyState from "../Global/EmptyState";
import LoadingMessage from "../Global/LoadingMessage";
import TenantDetailTabs from "../TenantDetails/TenantDetailTabs";
import {
  activeTenant,
  activeTenantActions,
  activeTenantAlerts,
  activeTenantHistoricalActions,
  deleteTenant,
  setSelectedTenant,
  activeTenantCustomerInfos,
  allAssignments,
} from "../../Features/Tenants/tenantSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Alert } from "../../Models/Alert";
import { GroupedAlert } from "../../Models/GroupedAlert";
import {
  deleteTenantById,
  getAllActionsByTenantId,
  getAllTemplateActions,
  getCustomerInfosByTenantId,
  getTenantAlertsById,
  getTenantById,
  getAllComments,
} from "../../Services/CommandCenterService";
import {
  allTemplateActions,
  setTemplateActions,
  allComments,
  setComments,
} from "../../Features/Config/configSlice";
import DeleteAlertDialog from "../Global/DeleteAlertDialog";
import { Assignment } from "../../Models/Assignment";

const TenantDetailHeader = () => {
  const { id } = useParams<any>();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const activeTenantObject = useAppSelector(activeTenant);
  const toast = useToast();

  const handleDelete = async () => {
    if (id) {
      await deleteTenantById(id);
    } else {
      console.error("ID is undefined");
    }

    dispatch(deleteTenant(Number(id)));

    toast({
      title: "Success!",
      description: `Tenant deleted`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    navigate("/tenants");
  };

  return (
    <Flex
      direction="row"
      minW="100%"
      align={["left"]}
      justifyContent="space-between"
    >
      <div>
        <Stat alignContent="left" alignItems="start">
          <StatNumber>{activeTenantObject?.tenant.azureTenantName}</StatNumber>
          <StatHelpText alignItems="flex-start">
            <Flex alignItems="flex-start">
              {activeTenantObject?.tenant.active ? (
                <div>
                  <CheckCircleIcon color="green.300" /> Active
                </div>
              ) : (
                <Text size="md">
                  <WarningTwoIcon color="red.300" /> Inactive
                </Text>
              )}{" "}
            </Flex>
          </StatHelpText>
        </Stat>
      </div>
      {activeTenantObject ? (
        <Stack direction="row">
          <EditTenantModal />
          <DeleteAlertDialog
            onDelete={handleDelete}
            size="md"
            item="Tenant"
            isButton={true}
          />
        </Stack>
      ) : null}
    </Flex>
  );
};

const TenantDetail = () => {
  const { id } = useParams<any>();

  const [groupedAlerts, setGroupedAlerts] = useState<Array<GroupedAlert>>([]);
  const [assignedConsultants, setAssignedConsultants] = useState<Assignment>();

  const tenant = useAppSelector(activeTenant);
  const actions = useAppSelector(activeTenantActions);
  const historicalActions = useAppSelector(activeTenantHistoricalActions);
  const alerts = useAppSelector(activeTenantAlerts);
  const templateActions = useAppSelector(allTemplateActions);
  const customerInfos = useAppSelector(activeTenantCustomerInfos);
  const comments = useAppSelector(allComments);
  const assignments = useAppSelector(allAssignments);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const loadData = async () => {
      if (id) {
        const tenantResult = await getTenantById(id);
        const actionsResult = await getAllActionsByTenantId(id, false); // Kun de som ikke er "handled by customer"
        const historicalActionsResult = await getAllActionsByTenantId(id, true); // Alle actions
        const templateActionsResult = await getAllTemplateActions();
        const customerInfosResult = await getCustomerInfosByTenantId(
          tenantResult.azureTenantId
        );
        const allCommentsResult = await getAllComments();
        dispatch(setComments(allCommentsResult));
        dispatch(setTemplateActions(templateActionsResult));

        // TODO: Fix this somehow? We should use database ID, not manually entered "azureTenantId"
        const alertsResult = await getTenantAlertsById(
          tenantResult.azureTenantId
        );

        dispatch(
          setSelectedTenant({
            tenant: tenantResult,
            actions: actionsResult,
            historicalActions: historicalActionsResult,
            alerts: alertsResult,
            customerInfos: customerInfosResult,
          })
        );
      } else {
        console.error("ID is undefined");
      }
    };

    id && loadData();
  }, [dispatch, id]);

  useEffect(() => {
    const grouped = groupBy(alerts, "alert");

    // Loop through dictionary
    const groupedList: Array<GroupedAlert> = Object.keys(grouped).map((key) => {
      const meta: Alert = grouped[key][0];
      const datesOrdered = grouped[key]
        .filter((i) => i.timeTriggered !== null)
        .map((t) => new Date(t.timeTriggered))
        .sort((a: Date, b: Date) => a.getDate() - b.getDate());

      // Get last element in datesOrdered
      const lastDate = datesOrdered[datesOrdered.length - 1];

      return {
        id: 0,
        alert: key,
        count: grouped[key].length,
        resourceName: meta.resourceName,
        timeTriggered: lastDate
          ? formatRelative(subDays(lastDate, 3), new Date())
          : "",
        alertDescription: meta.alertDescription,
        alertType: meta.alertType,
        link: meta.link,
        customer: meta.customer,
        severity: meta.severity,
        statusValue: meta.statusValue,
        title: meta.title,
      };
    });

    setGroupedAlerts(groupedList);
  }, [alerts]);

  if (!tenant) {
    return (
      <Flex direction="column">
        <TenantDetailHeader />
        <LoadingMessage message="Loading tenant details..." size="lg" />
      </Flex>
    );
  }

  return (
    <Flex direction="column">
      <TenantDetailHeader />

      {tenant ? (
        <>
          <TenantDetailTabs
            actions={actions ? actions : []}
            historicalActions={historicalActions ? historicalActions : []}
            alerts={groupedAlerts ? groupedAlerts : []}
            templateActions={templateActions}
            customerInfos={customerInfos ? customerInfos : []}
            comments={comments ? comments : []}
            assignments={assignments ? assignments : []}
          />
        </>
      ) : (
        <EmptyState heading="No tenant found" text="Go back and try again" />
      )}
    </Flex>
  );
};

export default TenantDetail;
