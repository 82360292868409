import { Flex, Heading } from "@chakra-ui/react";
import React, { useEffect } from "react";
import ConfigurationTabs from "./ConfigurationTabs";
import {
  allCategories,
  setCategories,
  allTemplateActions,
  setTemplateActions,
} from "../../Features/Config/configSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Category } from "../../Models/Category";
import {
  getAllAssignments,
  getAllCategories,
  getAllTemplateActions,
  getAllTenants,
} from "../../Services/CommandCenterService";
import {
  allAssignments,
  allTenants,
  setAssignments,
  setTenants,
} from "../../Features/Tenants/tenantSlice";

const ConfigurationHeader = () => {
  return (
    <Flex
      direction="row"
      minW="100%"
      align={["left"]}
      justifyContent="space-between"
      mb="2.5rem"
    >
      <Heading size="lg">Configuration</Heading>
    </Flex>
  );
};

const Configuration: React.FC = () => {
  const categories = useAppSelector(allCategories);
  const templateActions = useAppSelector(allTemplateActions);
  const tenants = useAppSelector(allTenants);
  const assignments = useAppSelector(allAssignments);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const loadData = async () => {
      const categoriesResult: Category[] = await getAllCategories();
      const templateActionsResult = await getAllTemplateActions();
      const tenantsResult = await getAllTenants();
      const assignmentsResult = await getAllAssignments();

      dispatch(setCategories(categoriesResult));
      dispatch(setTemplateActions(templateActionsResult));
      dispatch(setTenants(tenantsResult));
      dispatch(setAssignments(assignmentsResult));
    };
    loadData();
  }, [dispatch]);
  return (
    <Flex direction="column">
      <ConfigurationHeader />
      <ConfigurationTabs
        categories={categories}
        templateActions={templateActions}
        tenants={tenants}
        assignments={assignments}
      />
    </Flex>
  );
};

export default Configuration;
