import { Box, Button, Stack, useToast } from "@chakra-ui/react";
import { Table, Td, Th, Tr } from "../Global/Table";
import React, { useEffect, useState } from "react";
import { TemplateAction } from "../../Models/TemplateAction";
import { EditIcon } from "@chakra-ui/icons";
import { DescriptionModal } from "./DescriptionModal";
import EditTemplateActionModal from "./EditTemplateActionModal";
import { deleteTemplateAction } from "../../Services/CommandCenterService";
import { deleteTemplate } from "../../Features/Config/configSlice";
import { useAppDispatch } from "../../hooks";
import DeleteAlertDialog from "../Global/DeleteAlertDialog";

interface TemplateActionProps {
  items: TemplateAction[];
}

const TemplateActionTable: React.FC<TemplateActionProps> = ({ items }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [templateAction, setTemplateAction] = useState<TemplateAction>();
  const [description, setDescription] = useState<string>("");
  const dispatch = useAppDispatch();
  const toast = useToast();

  const handleEdit = (templateAction: TemplateAction) => {
    setTemplateAction(templateAction);
  };

  const toggleModal = (text: string) => {
    setIsOpen2(true);
    setDescription(text);
  };

  const handleDelete = async (id: number) => {
    await deleteTemplateAction(id);

    dispatch(deleteTemplate(id));

    toast({
      title: "Success!",
      description: `Template deleted`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  useEffect(() => {
    templateAction && setIsOpen(true);
  }, [templateAction]);

  return (
    <>
      {templateAction && (
        <EditTemplateActionModal
          isOpen={isOpen}
          templateAction={templateAction}
          onClose={() => {
            setTemplateAction(undefined);
            setIsOpen(false);
          }}
          onSave={() => {}}
        />
      )}
      {isOpen2 && (
        <DescriptionModal
          isOpen={isOpen2}
          text={description}
          onClose={() => {
            setIsOpen2(false);
            setDescription("");
          }}
        />
      )}

      <Table minW="100%">
        <thead>
          <Tr>
            <Th>Id</Th>
            <Th>Description</Th>
            <Th>Category</Th>
            <Th></Th>
          </Tr>
        </thead>
        <tbody>
          {items.map((i) => (
            <Box as="tr" key={i.id}>
              <Td fontSize="sm" fontWeight="small">
                {i.id}
              </Td>
              <Td
                fontSize="sm"
                style={{ cursor: i.shortText.length > 0 ? "pointer" : "" }}
                onClick={() => toggleModal(i.description)}
              >
                {i.shortText}
              </Td>
              <Td fontSize="sm" fontWeight="small">
                {i.category?.name}
              </Td>
              <Td>
                <Stack direction="row" justifyContent="flex-end">
                  <Button
                    onClick={() => handleEdit(i)}
                    leftIcon={<EditIcon />}
                    size="xs"
                    backgroundColor="gray.600"
                    color="white"
                    fontWeight="medium"
                    _hover={{ bg: "gray.700" }}
                    _active={{
                      bg: "gray.800",
                      transform: "scale(0.95)",
                    }}
                  >
                    Edit
                  </Button>
                  <DeleteAlertDialog
                    onDelete={() => handleDelete(i.id!)}
                    item="Template Action"
                    isButton={true}
                  />
                </Stack>
              </Td>
            </Box>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default TemplateActionTable;
