import React, { useState, useEffect } from "react";
import { listAllBlobs } from "../../Services/CommandCenterService";
import { Image, Grid, GridItem } from "@chakra-ui/react";
import { Blob } from "../../Models/blob";

interface ImageGalleryProps {
  onImageChanged: (imageUri: string) => void;
}

const ImageGallery = (props) => {
  const [images, setImages] = useState<Blob[]>([]);

  const handleImageClick = (imageUri) => {
    props.onImageChanged(imageUri);
  };

  useEffect(() => {
    listAllBlobs()
      .then((data) => {
        const sortedData = data.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setImages(sortedData);
      })
      .catch((error) => console.error("Error fetching blob data:", error));
  }, [images]);

  return (
    <>
      <Grid
        templateColumns="repeat(5, 2fr)"
        templateRows="repeat(2, 1fr)"
        gap={4}
        overflowY={"auto"}
        h={"60"}
        w={"100%"}
      >
        {images.map((image) => (
          <GridItem
            key={image.name}
            w="200px"
            h="200px"
            onClick={() => handleImageClick(image.uri)}
            borderRadius={"md"}
            boxShadow="md"
            overflow="hidden"
            cursor="pointer"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Image
              src={image.uri}
              alt="image"
              objectFit="cover"
              objectPosition="center"
              width="100%"
              height="100%"
              borderRadius={"md"}
            />
          </GridItem>
        ))}
      </Grid>
    </>
  );
};

export default ImageGallery;
