import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import * as React from "react";
import { modalMaxWidth } from "../Global/ModalSize";
import "../../Styles/editor.module.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  text: string;
};

const DescriptionModal: React.FC<Props> = ({ isOpen, onClose, text }) => {
  const formattedHtmlContent = text.replace(/\n/g, "<br/>");
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay style={{ zIndex: 1280 }} />
        <ModalContent maxW={modalMaxWidth}>
          <ModalHeader fontWeight="bold">Description</ModalHeader>
          <ModalCloseButton />
          <div className="ol">
            <ModalBody pb={0} pl={"1.5rem"} overflowY="auto" maxH="50vh">
              <div
                className="rdw-editor-main"
                dangerouslySetInnerHTML={{ __html: formattedHtmlContent }}
              />
            </ModalBody>
          </div>
          <ModalFooter>
            <Button onClick={onClose} mr={3} fontWeight="medium">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export { DescriptionModal };
