import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { addTenant } from "../../Features/Tenants/tenantSlice";
import { useAppDispatch } from "../../hooks";
import { Tenant } from "../../Models/Tenant";
import { createTenant } from "../../Services/CommandCenterService";
import { modalMaxWidth } from "../Global/ModalSize";

interface FormValues {
  azureTenantName: string;
  azureTenantId: string;
  azureAnalyticsId: string;
  powerBIReportId: string;
  powerBIUsageReportId: string;
  crmCustomerId: string;
  customerShortName: string;
}

const AddTenantModal = ({ children }) => {
  // const activeTenantSlice = useAppSelector(allTenants);
  const dispatch = useAppDispatch();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  // const { register, handleSubmit, control, errors } = useForm<IFormControl>();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>();

  const onCreateSite = async ({
    azureTenantName,
    azureTenantId,
    azureAnalyticsId,
    powerBIReportId,
    powerBIUsageReportId,
    crmCustomerId,
    customerShortName,
  }) => {
    const tenant: Tenant = {
      active: false, // Default to false
      azureTenantId,
      azureTenantName,
      azureAnalyticsId,
      powerBIReportId,
      powerBIUsageReportId,
      crmCustomerId,
      customerShortName,
    };
    const result = await createTenant(tenant);
    const res = await result.json();

    // Update the UI with the newly created action in the database
    dispatch(addTenant(res));

    toast({
      title: "Success!",
      description: `Tenant registered`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    onClose();
  };

  return (
    <>
      <Button
        onClick={onOpen}
        backgroundColor="gray.900"
        color="white"
        fontWeight="medium"
        _hover={{ bg: "gray.700" }}
        _active={{
          bg: "gray.800",
          transform: "scale(0.95)",
        }}
      >
        {children}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay style={{ zIndex: 1280 }} />
        <ModalContent
          as="form"
          onSubmit={handleSubmit(onCreateSite)}
          maxW={modalMaxWidth}
        >
          {/* <ModalContent as="form"> */}
          <ModalHeader fontWeight="bold">Add Tenant</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl isInvalid={!!errors.azureTenantName}>
              <FormLabel htmlFor="azureTenantName">Tenant</FormLabel>
              <Input
                id="azureTenantName"
                placeholder=""
                {...register("azureTenantName", {
                  required: "This is required",
                  // maxLength: { value: 18, message: "Max length is 18" },
                })}
              />
              <FormErrorMessage>
                {errors.azureTenantName &&
                typeof errors.azureTenantName.message === "string"
                  ? errors.azureTenantName.message
                  : undefined}
              </FormErrorMessage>
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Azure Tenant Id</FormLabel>
              <Input
                id="azureTenantId"
                placeholder="00000000-0000-0000-0000-000000000000"
                {...register("azureTenantId", {
                  required: "This is required",
                })}
              />
              <FormErrorMessage>
                {errors.azureTenantId &&
                typeof errors.azureTenantId.message === "string"
                  ? errors.azureTenantId.message
                  : undefined}
              </FormErrorMessage>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Azure Log Analytics Id</FormLabel>
              <Input
                placeholder="00000000-0000-0000-0000-000000000000"
                // name="azureAnalyticsId"
                {...register("azureAnalyticsId")}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>PowerBI Report Id</FormLabel>
              <Input
                placeholder="00000000-0000-0000-0000-000000000000"
                // name="powerBIReportId"
                {...register("powerBIReportId")}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>PowerBI Usage Report Id</FormLabel>
              <Input
                placeholder="00000000-0000-0000-0000-000000000000"
                {...register("powerBIUsageReportId")}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>CRM Customer Id</FormLabel>
              <Input placeholder="00000000000" {...register("crmCustomerId")} />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} mr={3} fontWeight="medium">
              Cancel
            </Button>
            <Button
              isLoading={isSubmitting}
              backgroundColor="#99FFFE"
              color="#194D4C"
              fontWeight="medium"
              type="submit"
            >
              Create
            </Button>
          </ModalFooter>
        </ModalContent>

        <DevTool control={control} />
      </Modal>
    </>
  );
};

export default AddTenantModal;
