import { PublicClientApplication } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Box, Button, ChakraProvider, Flex, Text } from "@chakra-ui/react";
import * as React from "react";
import { TiVendorMicrosoft } from "react-icons/ti";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { LOGIN_SCOPES, readRole } from "./authConfig";
import PageLayout from "./Styles/PageLayout";
import TenantDetail from "./Pages/TenantDetails/TenantDetail";
import Tenants from "./Pages/Tenants/Tenants";
import Configuration from "./Pages/Configurations/Configuration";
import Wiki from "./Pages/Wiki/Wiki";
import theme from "./Styles/Theme";
import { useEffect } from "react";
import { RouteGuard } from "./Pages/Global/RouteGuard";

const RedirectToTenants = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/tenants");
  }, [navigate]);

  return null;
};

const Pages = () => {
  return (
    <Routes>
      {/* Note - Defaulting to Tenants on base route for now */}
      <Route
        path="/"
        element={
          <RouteGuard roles={[readRole]}>
            <RedirectToTenants />{" "}
          </RouteGuard>
        }
      />
      <Route
        path="tenants"
        element={
          <RouteGuard roles={[readRole]}>
            <Tenants />
          </RouteGuard>
        }
      />
      <Route
        path="tenants/:id"
        element={
          <RouteGuard roles={[readRole]}>
            <TenantDetail />{" "}
          </RouteGuard>
        }
      />
      <Route
        path="configuration"
        element={
          <RouteGuard roles={[readRole]}>
            <Configuration />{" "}
          </RouteGuard>
        }
      />
      <Route
        path="wiki"
        element={
          <RouteGuard roles={[readRole]}>
            <Wiki />{" "}
          </RouteGuard>
        }
      />
    </Routes>
  );
};

interface IAppProps {
  instance: PublicClientApplication;
  loginError: string;
}

export const App: React.FC<IAppProps> = ({ instance, loginError }) => {
  if (loginError.length > 0) {
    return (
      <ChakraProvider theme={theme} resetCSS={true}>
        <Box backgroundColor="gray.100" h="100vh">
          <Flex
            as="main"
            direction="column"
            align="center"
            justify="center"
            h="100vh"
            maxW="400px"
            margin="0 auto"
          >
            <Text mb={4} fontSize="lg" p={6}>
              <Text as="span" fontWeight="bold" display="inline">
                PT:Kontroll
              </Text>
            </Text>
            <Button
              onClick={() => {
                instance.loginPopup({
                  scopes: LOGIN_SCOPES,
                  prompt: "select_account",
                });
              }}
              backgroundColor="white"
              color="black"
              fontWeight="medium"
              // _hover={{ bg: "red.300" }}
              _active={
                {
                  // bg: "gray.800",
                  // transform: "scale(0.95)",
                }
              }
              leftIcon={<TiVendorMicrosoft />}
            >
              Login with Microsoft
            </Button>
            {loginError}
          </Flex>
        </Box>
      </ChakraProvider>
    );
  }
  return (
    <Router>
      <MsalProvider instance={instance}>
        <ChakraProvider theme={theme} resetCSS={true}>
          <AuthenticatedTemplate>
            <PageLayout>
              <Pages />
            </PageLayout>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Box textAlign="center" fontSize="xl">
              <Box backgroundColor="gray.100" h="100vh">
                <Flex
                  as="main"
                  direction="column"
                  align="center"
                  justify="center"
                  h="100vh"
                  maxW="400px"
                  margin="0 auto"
                >
                  <Text mb={4} fontSize="lg" p={6}>
                    <Text as="span" fontWeight="bold" display="inline">
                      PT:Cloud Control
                    </Text>
                  </Text>
                  <Button
                    onClick={() =>
                      instance.loginPopup({ scopes: LOGIN_SCOPES })
                    }
                    backgroundColor="white"
                    color="black"
                    fontWeight="medium"
                    // _hover={{ bg: "red.300" }}
                    _active={
                      {
                        // bg: "gray.800",
                        // transform: "scale(0.95)",
                      }
                    }
                    leftIcon={<TiVendorMicrosoft />}
                  >
                    Login with Microsoft
                  </Button>
                </Flex>
              </Box>
            </Box>
          </UnauthenticatedTemplate>
        </ChakraProvider>
      </MsalProvider>
    </Router>
  );
};
