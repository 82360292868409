import React from "react";
import {
  Box,
  Card,
  CardHeader,
  Flex,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Skeleton,
  SkeletonCircle,
  VStack,
  Text,
} from "@chakra-ui/react";
import { SearchIcon, ViewOffIcon } from "@chakra-ui/icons";

export const SkeletonCard = () => (
  <Card p={2}>
    <CardHeader>
      <Flex>
        <Flex flex="2" justifyContent={"center"}>
          <SkeletonCircle size="12" />
        </Flex>
        <Flex flex="8" textAlign={"center"} justifyContent={"center"}>
          <VStack justifyContent="center">
            <Box>
              <Skeleton height={"15px"} width={"100px"} />
            </Box>
            <Box>
              <Skeleton height={"10px"} width={"150px"} />
            </Box>
          </VStack>
        </Flex>
      </Flex>
    </CardHeader>
  </Card>
);

const SkeletonTopRow = () => (
  <HStack align="center" mb={4}>
    <InputGroup maxW={"20%"}>
      <InputLeftElement pointerEvents="none">
        <SearchIcon color="gray.500" />
      </InputLeftElement>
      <Input
        placeholder="Search..."
        bgColor={"white"}
        borderRadius={50}
        boxShadow={"sm"}
        _hover={{
          bg: "transparent",
          color: "transpartent",
        }}
      />
    </InputGroup>

    <HStack spacing={0}>
      <IconButton
        aria-label="Toggle view"
        icon={<ViewOffIcon />}
        _hover={{ bg: "transparent" }}
      />
      <Text fontSize={"sm"} textColor={"gray.400"}>
        {"Showing Mine"}
      </Text>
    </HStack>
  </HStack>
);

const TenantTableSkeleton = () => {
  return (
    <>
      <SkeletonTopRow />
      <Grid templateColumns="repeat(5, 1fr)" gap={4}>
        <GridItem>
          <SkeletonCard />
        </GridItem>
        <GridItem>
          <SkeletonCard />
        </GridItem>
        <GridItem>
          <SkeletonCard />
        </GridItem>
      </Grid>
    </>
  );
};

export default TenantTableSkeleton;
