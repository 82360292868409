import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { addCategory } from "../../Features/Config/configSlice";
import { useAppDispatch } from "../../hooks";
import { Category } from "../../Models/Category";
import { createCategory } from "../../Services/CommandCenterService";
import { modalMaxWidth } from "../Global/ModalSize";
//import { Form } from "react-router-dom"; //Anna

interface FormValues {
  name: string;
}

const AddCategoryModal = ({ children }) => {
  const dispatch = useAppDispatch();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>();

  const onCreateCategory = async ({ name }) => {
    const newCategory: Category = {
      name: name,
    };

    const result = await createCategory(newCategory);
    const res = await result.json();

    dispatch(addCategory(res));

    toast({
      title: "Success!",
      description: `Category saved`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    onClose();
  };

  return (
    <>
      <Button
        onClick={onOpen}
        size="sm"
        backgroundColor="gray.900"
        color="white"
        fontWeight="medium"
        _hover={{ bg: "gray.700" }}
        _active={{
          bg: "gray.800",
          transform: "scale(0.95)",
        }}
      >
        {children}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay style={{ zIndex: 1280 }} />
        <ModalContent
          as="form"
          onSubmit={handleSubmit(onCreateCategory)}
          maxW={modalMaxWidth}
        >
          {/* <ModalContent as="form"> */}
          <ModalHeader fontWeight="bold">Add Action</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl isInvalid={!!errors.name}>
              <FormLabel>Name</FormLabel>
              <Input
                placeholder="Category name"
                id="name"
                {...register("name", {
                  required: "This is required",
                })}
              />
              <FormErrorMessage>
                {errors.name && typeof errors.name.message === "string"
                  ? errors.name.message
                  : undefined}
              </FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} mr={3} fontWeight="medium">
              Cancel
            </Button>
            <Button
              isLoading={isSubmitting}
              backgroundColor="#99FFFE"
              color="#194D4C"
              fontWeight="medium"
              type="submit"
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddCategoryModal;
