import React, { useEffect, useState } from "react";
import { Assignment } from "../../Models/Assignment";
import { useAppSelector } from "../../hooks";
import { activeTenant } from "../../Features/Tenants/tenantSlice";
import { Box, Text } from "@chakra-ui/react";
import { getAllMembers } from "../../Services/CommandCenterService";

interface IassignedComponentProps {
  items: Assignment[];
}

const AssignedComponent: React.FC<IassignedComponentProps> = ({ items }) => {
  const activeTenantSlice = useAppSelector(activeTenant);
  const tenant = activeTenantSlice?.tenant;
  const [members, setMembers] = useState<{ id: string; displayName: string }[]>(
    []
  );

  const activeTenantConsultants = items.filter(
    (i) => i.azureTenantId === tenant?.azureTenantId
  );

  useEffect(() => {
    async function fetchGroupMembers() {
      try {
        const groupId = "cc08e2b1-8db3-45f5-8b86-bbc563a1883b"; // pt-cloudcontrol-keyvaults
        const groupMembers = await getAllMembers(groupId);
        setMembers(groupMembers);
      } catch (error) {
        console.error("Failed to retrieve group members:", error);
      }
    }

    fetchGroupMembers();
  }, []);

  return (
    <>
      <Box>
        {/* Generate an array of displayNames and join them with a comma */}
        <Text fontSize={"md"} mb={6} fontStyle={"italic"} color={"GrayText"}>
          {activeTenantConsultants.length === 0
            ? "No assigned consultants"
            : activeTenantConsultants.length === 1
            ? "Assigned Consultant: "
            : "Assigned Consultants: "}

          {activeTenantConsultants
            .map((i) => {
              // Find the corresponding member to display displayName
              const member = members.find((member) => member.id === i.userId);
              return member ? member.displayName : "Unknown User";
            })
            .join(", ")}
        </Text>
      </Box>
    </>
  );
};

export default AssignedComponent;
