import { msalInstance } from "../AppAuth";
import { API_SCOPES } from "../authConfig";

export const getPhoto = async (userId, accessToken) => {
  const url = "https://graph.microsoft.com/v1.0/" + userId + "/photo/$value";

  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + accessToken.accessToken,
      "Content-Type": "application/json",
    },
  });
};

export const getToken = async () => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      "No active account! Verify a user has been signed in and setActiveAccount has been called."
    );
  }

  const userRequest = {
    scopes: API_SCOPES,
    account: account,
  };

  const response = await msalInstance.acquireTokenSilent(userRequest);

  return response.accessToken;
};

//Token that lets you fetch groups and user info
export const getToken2 = async () => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      "No active account! Verify a user has been signed in and setActiveAccount has been called."
    );
  }

  const userRequest = {
    scopes: ["GroupMember.Read.All", "Group.Read.All", "User.Read.All"],
    account: account,
  };

  try {
    const response = await msalInstance.acquireTokenSilent(userRequest);
    return response.accessToken;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to acquire token");
  }
};
