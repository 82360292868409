// functions for reuse

import React from "react";
import { Icon } from "@chakra-ui/react";
import { Category } from "../../Models/Category";
import {
  Security as SecurityIcon,
  Sell as SellIcon,
  Speed as SpeedIcon,
  EmojiEvents as EmojiEventsIcon,
  Update as UpdateIcon,
  Category as CategoryIcon,
  HomeRepairService,
  Devices,
  PermIdentity,
  Gavel as GavelIcon,
} from "@mui/icons-material";

//MMM DD, YYYY
export function formatDate(isoString: string): string {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(isoString);
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

//DD/MM/YYYY
export function formatDate2(isoString: string): string {
  const date = new Date(isoString);
  const day = date.getDate();
  const month = date.getMonth() + 1; // getMonth() returns 0-11, adding 1 to get 1-12
  const year = date.getFullYear();

  // Ensuring single-digit months and days are padded with a leading zero
  const paddedMonth = month < 10 ? `0${month}` : `${month}`;
  const paddedDay = day < 10 ? `0${day}` : `${day}`;

  return `${paddedDay}/${paddedMonth}/${year}`;
}

export const statusColor = (statusId: number) => {
  switch (statusId) {
    case 1:
      return "#2DCCFF";
    case 2:
      return "#56F000";
    case 3:
      return "#FCE83A";
    case 4:
      return "#c8c6c4";
    default:
      return "transparent";
  }
};

export const categoryIcon = (category: Category) => {
  switch (category.name) {
    case "Security":
      return <Icon as={SecurityIcon} boxSize={3} />;
    case "Cost":
      return <Icon as={SellIcon} boxSize={3} />;
    case "Performance":
      return <Icon as={SpeedIcon} boxSize={3} />;
    case "Operational Excellence":
      return <Icon as={EmojiEventsIcon} boxSize={3} />;
    case "Availability":
      return <Icon as={UpdateIcon} boxSize={3} />;
    case "PT:Toolbox":
      return <Icon as={HomeRepairService} boxSize={3} />;
    case "Identity":
      return <Icon as={PermIdentity} boxSize={3} />;
    case "Devices":
      return <Icon as={Devices} boxSize={3} />;
    case "Governance":
      return <Icon as={GavelIcon} boxSize={3} />;
    default:
      return <Icon as={CategoryIcon} boxSize={3} />;
  }
};
