import {
  Box,
  Text,
  Image,
  HStack,
  Heading,
  Flex,
  VStack,
  StackDivider,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { CustomerInfo } from "../../Models/CustomerInfo";
import { format } from "date-fns";
import FileUpload from "../Global/FileUpload";
import { listAllBlobs } from "../../Services/CommandCenterService";
import { Blob } from "../../Models/blob";
import { activeTenant } from "../../Features/Tenants/tenantSlice";
import { useAppSelector } from "../../hooks";
import LoadingMessage from "../Global/LoadingMessage";

interface ICustomerInfoTableProps {
  items: CustomerInfo[];
}

const CustomerInfoTable: React.FC<ICustomerInfoTableProps> = ({ items }) => {
  const [images, setImages] = useState<Blob[]>([]);
  const [loading, setIsLoading] = useState(true);

  const InfoRow: React.FC<{
    title: string;
    content: string | number | null;
    maxH?: string;
    minH?: string;
  }> = ({ title, content, maxH = "1rem", minH = "1rem" }) => (
    <>
      <Box textAlign={"left"} minW="100%">
        <Heading
          size="sm"
          fontWeight={"var(--chakra-fontWeights-semibold)"}
          fontFamily="var(--chakra-fonts-body)"
          mb="0.5rem"
        >
          {title}
        </Heading>
        <Text fontSize="sm" maxH={maxH} minH={minH}>
          {content}
        </Text>
      </Box>
    </>
  );
  const InfoRowBig: React.FC<{
    title: string;
    content: string | number | null;
    maxH?: string;
    minH?: string;
  }> = ({ title, content, maxH = "6rem", minH = "6rem" }) => (
    <>
      <Box textAlign={"left"} minW="100%" bgColor={"gray.200"} borderRadius={6}>
        <Heading
          size="sm"
          fontWeight={"var(--chakra-fontWeights-semibold)"}
          fontFamily="var(--chakra-fonts-body)"
          p="2"
        >
          {title}
        </Heading>
        <Text fontSize="sm" maxH={maxH} minH={minH} overflowY={"auto"} px="2">
          {content}
        </Text>
      </Box>
    </>
  );

  const activeTenantSlice = useAppSelector(activeTenant);
  const tenant = activeTenantSlice?.tenant;

  useEffect(() => {
    listAllBlobs()
      .then((data) => {
        // Filters images based on azure tenant ID and filename match
        const filteredImages = data.filter((image) =>
          image.name.includes(tenant?.azureTenantId)
        );
        setImages(filteredImages);
      })
      .catch((error) => console.error("Error fetching blob data:", error))
      .finally(() => setIsLoading(false));
  }, [images, tenant?.azureTenantId]);

  return (
    <>
      <Box h="51vh" overflowY="visible">
        {items.map((i) => (
          <HStack p="2" spacing={4} minW="100%" key={i.azureTenantId}>
            {/*<Box flex="1" width="50%" alignItems="start">*/}

            <Box
              key={i.azureTenantId}
              flex="2"
              maxWidth="70%"
              alignItems="start" /*backgroundColor="orange.100"*/
            >
              <HStack spacing={6} alignItems="start">
                <VStack
                  width={"23%"}
                  textAlign={"left"}
                  divider={<StackDivider borderColor="gray.200" />}
                  //backgroundColor="gray.100"
                >
                  <InfoRow
                    title="Customer Name"
                    content={i.customerName ?? ""}
                  />
                  <InfoRow title="Contact Name" content={i.contactName ?? ""} />
                  <InfoRow
                    title="Contact Mobile"
                    content={i.contactMobile ?? ""}
                  />
                  <InfoRow
                    title="Contact Email"
                    content={i.contactEmail ?? ""}
                  />
                  <InfoRow
                    title="Onboarded At"
                    content={
                      i.onboardedAt
                        ? format(new Date(i.onboardedAt), "dd.MM.yyyy")
                        : null
                    }
                  />
                  <InfoRow
                    title="Subscriptions"
                    content={i.subscriptions ?? null}
                  />
                  <InfoRow
                    title="Customer Price"
                    content={i.customerPrice ?? null}
                  />
                </VStack>
                <VStack
                  width={"54%"}
                  textAlign={"left"}
                  divider={<StackDivider borderColor="gray.200" />}
                >
                  <InfoRowBig
                    title="CAF Journey"
                    content={i.cafJourney ?? ""}
                  />
                  <InfoRowBig
                    title="IT-Department"
                    content={i.itDepartment ?? ""}
                  />
                  <InfoRowBig
                    title="Describe Customer"
                    content={i.describeCustomer ?? ""}
                  />
                </VStack>
                <VStack
                  width={"23%"}
                  textAlign={"left"}
                  divider={<StackDivider borderColor="gray.200" />}
                >
                  <InfoRow
                    title="Azure Tenant ID"
                    content={tenant?.azureTenantId ?? ""}
                  />
                  <InfoRow
                    title="Azure Analytics ID"
                    content={tenant?.azureAnalyticsId ?? ""}
                  />
                  <InfoRow
                    title="CC Report ID"
                    content={tenant?.powerBIReportId ?? ""}
                  />
                  <InfoRow
                    title="Usage Report ID"
                    content={tenant?.powerBIUsageReportId ?? ""}
                  />
                  <InfoRow
                    title="CRM ID"
                    content={tenant?.crmCustomerId ?? ""}
                  />
                  <InfoRow
                    title="Customer Short Name"
                    content={tenant?.customerShortName ?? ""}
                  />
                </VStack>
              </HStack>
            </Box>

            <Box
              flex="1"
              maxWidth="30%"
              alignItems="start"
              overflow="hidden"
              borderRadius="lg"
              //backgroundColor={'pink.200'}
            >
              <Flex
                height="100%"
                alignItems="center"
                justifyContent="center"
                //backgroundColor={'pink.100'}
              >
                {images.length ? (
                  loading ? (
                    <LoadingMessage message="Loading image" size="xl" />
                  ) : (
                    images.map((image) => (
                      <Image
                        key={image.uri}
                        src={image.uri}
                        alt="Tenant Image"
                      />
                    ))
                  )
                ) : (
                  <FileUpload filename={i.azureTenantId} />
                )}
              </Flex>
            </Box>
          </HStack>
        ))}
      </Box>
    </>
  );
};

export default CustomerInfoTable;
