import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { Category } from "../../Models/Category";
import { TemplateAction } from "../../Models/TemplateAction";
import { Status } from "../../Models/Status";
import { Comment } from "../../Models/Comment";

interface ConfigState {
  categories: Category[];
  templateActions: TemplateAction[];
  statuses: Status[];
  comments: Comment[];
}

const initialState: ConfigState = {
  categories: [],
  templateActions: [],
  statuses: [],
  comments: [],
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<Array<Category>>) => {
      state.categories = action.payload;
    },
    setTemplateActions: (
      state,
      action: PayloadAction<Array<TemplateAction>>
    ) => {
      state.templateActions = action.payload;
    },
    setStatuses: (state, action: PayloadAction<Array<Status>>) => {
      state.statuses = action.payload;
    },
    setComments: (state, action: PayloadAction<Array<Comment>>) => {
      state.comments = action.payload;
    },
    addActionComment: (state, action: PayloadAction<Comment>) => {
      state.comments = [...state.comments, action.payload];
    },
    editComment: (state, action: PayloadAction<Comment>) => {
      state.comments = state.comments.map((c) =>
        c.id === action.payload.id ? action.payload : c
      );
    },
    deleteComment: (state, action: PayloadAction<number>) => {
      state.comments = state.comments.filter((c) => c.id! !== action.payload);
    },
    addTemplateAction: (state, action: PayloadAction<TemplateAction>) => {
      state.templateActions = [...state.templateActions, action.payload];
    },
    editTemplateAction: (state, action: PayloadAction<TemplateAction>) => {
      state.templateActions = state.templateActions.map((t) =>
        t.id === action.payload.id ? action.payload : t
      );
    },
    deleteTemplate: (state, action: PayloadAction<number>) => {
      state.templateActions = state.templateActions.filter(
        (t) => t.id! !== action.payload
      );
    },
    addCategory: (state, action: PayloadAction<Category>) => {
      state.categories = [...state.categories, action.payload];
    },
    editCategory: (state, action: PayloadAction<Category>) => {
      state.categories = state.categories.map((c) =>
        c.id === action.payload.id ? action.payload : c
      );
    },
  },
});

export const {
  setCategories,
  setTemplateActions,
  setStatuses,
  setComments,
  addActionComment,
  editComment,
  deleteComment,
  addTemplateAction,
  editTemplateAction,
  deleteTemplate,
  addCategory,
  editCategory,
} = configSlice.actions;

export const allCategories = (state: RootState) => state.config.categories;
export const allTemplateActions = (state: RootState) =>
  state.config.templateActions;
export const allStatuses = (state: RootState) => state.config.statuses;
export const allComments = (state: RootState) => state.config.comments;
export default configSlice.reducer;
