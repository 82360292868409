import { Flex, Heading } from "@chakra-ui/react";
import React, { useEffect } from "react";
import {
  allSubPages,
  allWikiPages,
  setSubPages,
  setWikiPages,
} from "../../Features/WikiPages/wikiSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { WikiPage } from "../../Models/WikiPage";
import {
  getAllWikiPages,
  getAllSubPages,
} from "../../Services/CommandCenterService";
import WikiTabs from "./WikiTabs";
import AddWikiPageModal from "./AddWikiPageModal";
import { SubPage } from "../../Models/SubPage";
import LoadingMessage from "../Global/LoadingMessage";

const ConfigurationHeader = () => {
  return (
    <Flex
      direction="row"
      minW="100%"
      align={["left"]}
      justifyContent="space-between"
      mb="2.5rem"
      alignItems="center"
    >
      <Heading size="lg">Wiki</Heading>
      <AddWikiPageModal>+ Add New Page </AddWikiPageModal>
    </Flex>
  );
};

const Wiki: React.FC = () => {
  const wikiPages = useAppSelector(allWikiPages);
  const subPages = useAppSelector(allSubPages);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const loadData = async () => {
      const wikiPagesResult: WikiPage[] = await getAllWikiPages();
      const subPagesResult: SubPage[] = await getAllSubPages();
      dispatch(setSubPages(subPagesResult));
      dispatch(setWikiPages(wikiPagesResult));
    };

    loadData();
  }, [dispatch]);

  if (!wikiPages) {
    return (
      <Flex direction="column">
        <ConfigurationHeader />
        <LoadingMessage message="Loading wiki details..." size="lg" />
      </Flex>
    );
  }

  return (
    <Flex direction="column">
      <ConfigurationHeader />
      <WikiTabs wikiPages={wikiPages} subPages={subPages} />
    </Flex>
  );
};

export default Wiki;
