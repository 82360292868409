import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Switch,
  useDisclosure,
  useToast,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { allCategories, allStatuses } from "../../Features/Config/configSlice";
import {
  activeTenant,
  addTenantAction,
  allTenants,
} from "../../Features/Tenants/tenantSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Action } from "../../Models/Action";
import {
  createActionOnTenant,
  getAllMembers,
} from "../../Services/CommandCenterService";
import TinyEditor from "../Global/TinyEditor";

interface FormValues {
  azureTenantName: string;
  description: string;
  shortText: string;
  handledByCustomer: boolean;
  assistanceRequired: boolean;
  estimate: number;
  category: string;
  status: string;
  handledBy: string;
  isGlobal: boolean;
}

const AddTenantActionModal = ({ children }) => {
  const categories = useAppSelector(allCategories);
  const statuses = useAppSelector(allStatuses);
  const activeTenantSlice = useAppSelector(activeTenant);
  const dispatch = useAppDispatch();
  const [warning, setWarning] = React.useState(false);
  const tenants = useAppSelector(allTenants);
  const [members, setMembers] = useState<{ id: string; displayName: string }[]>(
    []
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const tenant = activeTenantSlice?.tenant;

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
    control,
  } = useForm<FormValues>();

  const handleClose = () => {
    reset();
    onClose();
  };

  const onCreateAction = async ({
    description,
    shortText,
    handledByCustomer,
    assistanceRequired,
    estimate,
    category,
    status,
    handledBy,
    isGlobal,
  }) => {
    const selectedCategoryObj = categories.find(
      (c) => c.id === Number(category)
    );

    const defaultStatusObj = statuses.find((s) => s.id === 1);

    if (isGlobal) {
      // Create action on all tenants
      await Promise.all(
        tenants.map(async (tenant) => {
          const newAction: Action = {
            tenantId: tenant?.id ? tenant.id : 0,
            azureTenantId: tenant!.azureTenantId,
            description: description,
            shortText: shortText,
            handledByCustomer: handledByCustomer,
            categoryId: selectedCategoryObj!.id!,
            category: {
              name: selectedCategoryObj!.name,
            }!,
            assistanceRequired: assistanceRequired,
            estimate: estimate,
            statusId: 1,
            status: {
              name: defaultStatusObj!.name,
            }!,
            handledBy: handledBy,
            isGlobal: isGlobal,
          };

          const result = await createActionOnTenant(newAction);
          const res = await result.json();

          // Update the UI with the newly created action in the database
          dispatch(addTenantAction(res));
        })
      );
      toast({
        title: "Success!",
        description: `Action registered on all tenants.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } else {
      // Create action on selected tenant
      const newAction: Action = {
        tenantId: tenant?.id ? tenant.id : 0,
        azureTenantId: tenant!.azureTenantId,
        description: description,
        shortText: shortText,
        handledByCustomer: handledByCustomer,
        categoryId: selectedCategoryObj!.id!,
        category: {
          name: selectedCategoryObj!.name,
        }!,
        assistanceRequired: assistanceRequired,
        estimate: estimate,
        statusId: 1,
        status: {
          name: defaultStatusObj!.name,
        }!,
        handledBy: handledBy,
        isGlobal: isGlobal,
      };

      const result = await createActionOnTenant(newAction);
      const res = await result.json();

      // Update the UI with the newly created action in the database
      dispatch(addTenantAction(res));
      toast({
        title: "Success!",
        description: `Action registered on ${tenant?.azureTenantName}`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }

    handleClose();
  };

  useEffect(() => {
    async function fetchGroupMembers() {
      try {
        const groupId = "cc08e2b1-8db3-45f5-8b86-bbc563a1883b"; // pt-cloudcontrol-keyvaults
        const groupMembers = await getAllMembers(groupId);
        setMembers(groupMembers);
      } catch (error) {
        console.error("Failed to retrieve group members:", error);
      }
    }
    fetchGroupMembers();
  }, [tenant?.azureTenantId]);

  return (
    <>
      <Button
        onClick={onOpen}
        size="sm"
        backgroundColor="gray.900"
        color="white"
        fontWeight="medium"
        _hover={{ bg: "gray.700" }}
        _active={{
          bg: "gray.800",
          transform: "scale(0.95)",
        }}
      >
        {children}
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        size="6xl"
        blockScrollOnMount={false}
        trapFocus={false}
        returnFocusOnClose={false}
      >
        <ModalOverlay style={{ zIndex: 1280 }} />
        <ModalContent as="form" onSubmit={handleSubmit(onCreateAction)}>
          <ModalHeader fontWeight="bold">Add Action</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={4}>
            <Stack direction={"row"} spacing={2} align={"start"}>
              <FormControl w={"20%"}>
                <FormLabel htmlFor="azureTenantName" ml={1}>
                  Tenant
                </FormLabel>
                <Input
                  id="azureTenantName"
                  placeholder=""
                  value={tenant?.azureTenantName}
                  disabled={true}
                  {...register("azureTenantName")}
                />
              </FormControl>
              <FormControl isInvalid={!!errors.shortText} w={"60%"}>
                <FormLabel ml={1}>Title</FormLabel>
                <Input
                  placeholder="Action short description"
                  id="shortText"
                  {...register("shortText", {
                    required: "This is required",
                    maxLength: 128,
                  })}
                />
                <FormErrorMessage>
                  {errors.shortText?.type === "maxLength" && (
                    <span>Max 128 characters</span>
                  )}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.category} w={"20%"}>
                <FormLabel ml={1} htmlFor="category">
                  Category
                </FormLabel>
                <Select
                  id="category"
                  {...register("category", {
                    required: "This is required",
                  })}
                  placeholder="Select category"
                  width="auto"
                >
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <FormControl mt={4} isInvalid={!!errors.description}>
              <FormLabel>Image Gallery</FormLabel>
              <Controller
                name="description"
                control={control}
                defaultValue=""
                rules={{ required: "Description is required" }}
                render={({ field }) => (
                  <TinyEditor
                    content={field.value}
                    onContentChange={field.onChange}
                    editorTitle="Description"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.description &&
                typeof errors.description.message === "string"
                  ? errors.description.message
                  : undefined}
              </FormErrorMessage>
            </FormControl>

            <Stack direction={"row"} mt={4} spacing={2} align={"start"} mx={2}>
              <FormControl w={"50%"}>
                <FormLabel>Activate for all customers?</FormLabel>
                <Stack direction={"row"} align={"end"} spacing={4}>
                  <Switch
                    mt={2}
                    size={"lg"}
                    id="isGlobal"
                    {...register("isGlobal")}
                    colorScheme="orange"
                    defaultChecked={warning}
                    onChange={(e) => setWarning(e.target.checked)}
                  />
                  {warning && (
                    <Text
                      fontSize={"2xs"}
                      color={"gray.500"}
                      fontStyle={"italic"}
                    >
                      NB! Creates this action on all tenants.
                    </Text>
                  )}
                  {!warning && (
                    <Text
                      fontSize={"2xs"}
                      color={"gray.500"}
                      fontStyle={"italic"}
                    >
                      This action will only be created <br /> on the current
                      tenant.
                    </Text>
                  )}
                </Stack>
              </FormControl>

              <FormControl isInvalid={!!errors.estimate}>
                <FormLabel>Estimate</FormLabel>
                <Input
                  placeholder="Estimate in hours, e.g. 0.5 or 4."
                  id="estimate"
                  {...register("estimate", {
                    maxLength: 8,
                  })}
                />
                <FormErrorMessage>
                  {errors.estimate?.type === "maxLength" && (
                    <span>Max 8 characters</span>
                  )}
                </FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel>Assigned To</FormLabel>
                <Select
                  id="handledBy"
                  {...register("handledBy")}
                  placeholder="Select consultant"
                  width="auto"
                >
                  {members.map((m) => (
                    <option key={m.id} value={m.displayName}>
                      {m.displayName}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} mr={3} fontWeight="medium">
              Cancel
            </Button>
            <Button
              isLoading={isSubmitting}
              colorScheme="orange"
              fontWeight="medium"
              type="submit"
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddTenantActionModal;
