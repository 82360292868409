import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { WikiPage } from "../../Models/WikiPage";
import { SubPage } from "../../Models/SubPage";

interface WikiState {
  wikiPages: WikiPage[];
  subPages: SubPage[];
}

const initialState: WikiState = {
  wikiPages: [],
  subPages: [],
};

export const wikiSlice = createSlice({
  name: "wiki",
  initialState,
  reducers: {
    setWikiPages: (state, action: PayloadAction<Array<WikiPage>>) => {
      state.wikiPages = action.payload;
    },
    addWikiPage: (state, action: PayloadAction<WikiPage>) => {
      state.wikiPages = [...state.wikiPages, action.payload];
    },
    editWikiPage: (state, action: PayloadAction<WikiPage>) => {
      state.wikiPages = state.wikiPages.map((w) =>
        w.id === action.payload.id ? action.payload : w
      );
    },
    deleteWikiPage: (state, action: PayloadAction<number>) => {
      state.wikiPages = state.wikiPages.filter((w) => w.id! !== action.payload);
    },
    setSubPages: (state, action: PayloadAction<Array<SubPage>>) => {
      state.subPages = action.payload;
    },
    addSubPage: (state, action: PayloadAction<SubPage>) => {
      state.subPages = [...state.subPages, action.payload];
    },
    editSubPage: (state, action: PayloadAction<SubPage>) => {
      state.subPages = state.subPages.map((s) =>
        s.id === action.payload.id ? action.payload : s
      );
    },
    deleteSubPage: (state, action: PayloadAction<number>) => {
      state.subPages = state.subPages.filter((s) => s.id! !== action.payload);
    },
  },
});

export const {
  setWikiPages,
  addWikiPage,
  editWikiPage,
  deleteWikiPage,
  addSubPage,
  editSubPage,
  deleteSubPage,
  setSubPages,
} = wikiSlice.actions;

export const allSubPages = (state: RootState) => state.wiki.subPages;
export const allWikiPages = (state: RootState) => state.wiki.wikiPages;
export default wikiSlice.reducer;
