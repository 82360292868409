import { EditIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  Textarea,
  Heading,
  Tooltip,
  Flex,
  HStack,
  Box,
  IconButton,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  activeTenant,
  editCustomerInfo,
} from "../../Features/Tenants/tenantSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { CustomerInfo } from "../../Models/CustomerInfo";
import {
  deleteFile,
  updateCustomerInfoOnTenant,
} from "../../Services/CommandCenterService";
import { modalHuge } from "../Global/ModalSize";

interface FormValues {
  azureTenantId: string;
  customerName: string;
  contactName: string;
  contactMobile: string;
  contactEmail: string;
  onboardedAt: string;
  subscriptions: string;
  customerPrice: string;
  cafJourney: string;
  itDepartment: string;
  describeCustomer: string;
}

const EditCustomerInfoModal = () => {
  const activeTenantObject = useAppSelector(activeTenant);
  const dispatch = useAppDispatch();

  const [state, setState] = useState<CustomerInfo>();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>();

  const onEditCustomerInfo = async ({
    azureTenantId,
    customerName,
    contactName,
    contactMobile,
    contactEmail,
    onboardedAt,
    subscriptions,
    customerPrice,
    cafJourney,
    itDepartment,
    describeCustomer,
  }) => {
    const customerInfo: CustomerInfo = {
      azureTenantId: azureTenantId ?? state?.azureTenantId,
      customerName: customerName ?? state?.customerName,
      contactName: contactName ?? state?.contactName,
      contactMobile: contactMobile ?? state?.contactMobile,
      contactEmail: contactEmail ?? state?.contactEmail,
      onboardedAt: onboardedAt ?? state?.onboardedAt,
      subscriptions: subscriptions ?? state?.subscriptions,
      customerPrice: customerPrice ?? state?.customerPrice,
      cafJourney: cafJourney ?? state?.cafJourney,
      itDepartment: itDepartment ?? state?.itDepartment,
      describeCustomer: describeCustomer ?? state?.describeCustomer,
    };
    await updateCustomerInfoOnTenant(customerInfo);

    // Update the UI with the newly created action in the database
    dispatch(editCustomerInfo(customerInfo));

    toast({
      title: "Success!",
      description: `Info updated`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    onClose();
  };

  useEffect(() => {
    activeTenantObject && setState(activeTenantObject!.customerInfos[0]);
  }, [activeTenantObject]);

  const shortField = "60%";
  const [isHovered, setIsHovered] = useState(false);

  const handleDelete = async () => {
    await deleteFile(state!.azureTenantId);

    toast({
      title: "Success!",
      description: `Tenant image deleted`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <>
      <IconButton
        aria-label="Edit customer info button"
        onClick={onOpen}
        backgroundColor="white"
        color="black"
        _hover={{ bg: "white", color: "black" }}
        _active={{
          bg: "white",
          color: "black",
          transform: "none",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        icon={<EditIcon boxSize={isHovered ? "5" : "4"} />}
        size="md"
      ></IconButton>
      {state ? (
        <Modal isOpen={isOpen} onClose={onClose} size={modalHuge}>
          <ModalOverlay style={{ zIndex: 1280 }} />
          <ModalContent
            as="form"
            onSubmit={handleSubmit(onEditCustomerInfo)}
            maxHeight="87vh"
            overflowY="auto"
          >
            {/* <ModalContent as="form"> */}
            <ModalHeader
              fontWeight="bold"
              pb="1"
              fontFamily="var(--chakra-fonts-body)"
            >
              Edit Customer Info
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Box px="1rem">
                <FormControl isInvalid={!!errors.customerName}>
                  <FormLabel htmlFor="customerName" fontSize="sm">
                    Customer Name
                  </FormLabel>
                  <Input
                    size="sm"
                    width="30%"
                    value={state?.customerName}
                    id="customerName"
                    placeholder="Name of customer"
                    {...register("customerName", {
                      required: "This is required",
                    })}
                    onChange={(e) => {
                      setState((prevState: any) => {
                        return {
                          ...prevState,
                          customerName: e.target.value,
                        };
                      });
                    }}
                  />
                  <FormErrorMessage>
                    {errors.customerName &&
                    typeof errors.customerName.message === "string"
                      ? errors.customerName.message
                      : undefined}
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <HStack width="full" align="flex-start" px="1rem" pt="1rem">
                <Box flex="1" width="50%">
                  <Heading
                    as="h2"
                    size="md"
                    px="0"
                    mb={2}
                    marginTop="0"
                    fontFamily="var(--chakra-fonts-body)"
                  >
                    Configuration
                  </Heading>

                  <FormControl>
                    <FormLabel fontSize="sm">Azure Tenant Id</FormLabel>
                    <Input
                      size="sm"
                      width={shortField}
                      value={state?.azureTenantId}
                      id="azureTenantId"
                      placeholder="00000000-0000-0000-0000-000000000000"
                      {...register("azureTenantId", {
                        required: "This is required",
                      })}
                      onChange={(e) => {
                        setState((prevState: any) => {
                          return {
                            ...prevState,
                            azureTenantId: e.target.value,
                          };
                        });
                      }}
                    />
                    <FormErrorMessage>
                      {errors.azureTenantId &&
                      typeof errors.azureTenantId.message === "string"
                        ? errors.azureTenantId.message
                        : undefined}
                    </FormErrorMessage>
                  </FormControl>
                  <Heading
                    as="h2"
                    size="md"
                    marginTop="1rem"
                    fontFamily="var(--chakra-fonts-body)"
                  >
                    Contact person
                  </Heading>
                  <FormControl mt={2}>
                    <FormLabel fontSize="sm">Contact Name</FormLabel>
                    <Input
                      size="sm"
                      width={shortField}
                      value={state?.contactName}
                      id="contactName"
                      {...register("contactName")}
                      onChange={(e) => {
                        setState((prevState: any) => {
                          return {
                            ...prevState,
                            contactName: e.target.value,
                          };
                        });
                      }}
                    />
                    <FormErrorMessage>
                      {errors.contactName &&
                      typeof errors.contactName.message === "string"
                        ? errors.contactName.message
                        : undefined}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl mt={4}>
                    <FormLabel fontSize="sm">Contact Mobile</FormLabel>
                    <Input
                      size="sm"
                      width={shortField}
                      value={state?.contactMobile}
                      {...register("contactMobile")}
                      onChange={(e) => {
                        setState((prevState: any) => {
                          return {
                            ...prevState,
                            contactMobile: e.target.value,
                          };
                        });
                      }}
                    />
                  </FormControl>
                  <FormControl mt={4}>
                    <FormLabel fontSize="sm">Contact Email</FormLabel>
                    <Input
                      size="sm"
                      width={shortField}
                      value={state?.contactEmail}
                      {...register("contactEmail")}
                      onChange={(e) => {
                        setState((prevState: any) => {
                          return {
                            ...prevState,
                            contactEmail: e.target.value,
                          };
                        });
                      }}
                    />
                  </FormControl>
                  <Heading
                    as="h2"
                    size="md"
                    marginTop="1rem"
                    fontFamily="var(--chakra-fonts-body)"
                  >
                    Pricing
                  </Heading>
                  <FormControl mt={2}>
                    <Flex alignItems="center">
                      <FormLabel mr="0.5rem" fontSize="sm">
                        Customer Price
                      </FormLabel>
                      <Tooltip
                        hasArrow
                        label="Agreed price"
                        bg="gray.200"
                        color="gray.700"
                        placement="top-start"
                        sx={{
                          fontSize: "sm",
                          fontWeight: "light",
                          p: 2,
                          borderRadius: "md",
                          boxShadow: "md",
                        }}
                      >
                        <InfoOutlineIcon mr="12px" mb="8px" boxSize={3} />
                      </Tooltip>
                    </Flex>

                    <Input
                      size="sm"
                      width={shortField}
                      value={state?.customerPrice}
                      placeholder=""
                      {...register("customerPrice")}
                      onChange={(e) => {
                        setState((prevState: any) => {
                          return {
                            ...prevState,
                            customerPrice: e.target.value,
                          };
                        });
                      }}
                    />
                  </FormControl>

                  <FormControl mt={4}>
                    <Flex alignItems="center">
                      <FormLabel mr="0.5rem" fontSize="sm">
                        Subscriptions
                      </FormLabel>
                      <Tooltip
                        hasArrow
                        label="Number of"
                        bg="gray.200"
                        color="gray.700"
                        placement="top-start"
                        sx={{
                          fontSize: "sm",
                          fontWeight: "light",
                          p: 2,
                          borderRadius: "md",
                          boxShadow: "md",
                        }}
                      >
                        <InfoOutlineIcon mr="12px" mb="8px" boxSize={3} />
                      </Tooltip>
                    </Flex>
                    <Input
                      size="sm"
                      width={shortField}
                      value={state?.subscriptions}
                      placeholder="Number of subscriptions"
                      {...register("subscriptions")}
                      onChange={(e) => {
                        setState((prevState: any) => {
                          return {
                            ...prevState,
                            subscriptions: e.target.value,
                          };
                        });
                      }}
                    />
                  </FormControl>
                </Box>
                <Box flex="1" width="50%">
                  <Heading
                    as="h2"
                    size="md"
                    marginTop="1rem"
                    fontFamily="var(--chakra-fonts-body)"
                  >
                    General Information
                  </Heading>
                  <FormControl mt={2}>
                    <Flex alignItems="center">
                      <FormLabel mr="0.5rem" fontSize="sm">
                        Onboarded At
                      </FormLabel>
                      <Tooltip
                        hasArrow
                        label="When did we install CC?"
                        bg="gray.200"
                        color="gray.700"
                        placement="top-start"
                        sx={{
                          fontSize: "sm",
                          fontWeight: "light",
                          p: 2,
                          borderRadius: "md",
                          boxShadow: "md",
                        }}
                      >
                        <InfoOutlineIcon mr="12px" mb="8px" boxSize={3} />
                      </Tooltip>
                    </Flex>
                    <Input
                      size="sm"
                      width={shortField}
                      value={state?.onboardedAt}
                      placeholder="yyyy-mm-dd"
                      {...register("onboardedAt")}
                      onChange={(e) => {
                        setState((prevState: any) => {
                          return {
                            ...prevState,
                            onboardedAt: e.target.value,
                          };
                        });
                      }}
                    />
                  </FormControl>
                  <FormControl mt={4}>
                    <Flex alignItems="center">
                      <FormLabel mr="0.5rem" fontSize="sm">
                        CAF Journey
                      </FormLabel>
                      <Tooltip
                        hasArrow
                        label="Regarding their cloud journey, describe where they are, what are their goals (hybrid, cloud only, how big on-premise)."
                        bg="gray.200"
                        color="gray.700"
                        placement="top-start"
                        sx={{
                          fontSize: "sm",
                          fontWeight: "light",
                          p: 2,
                          borderRadius: "md",
                          boxShadow: "md",
                        }}
                      >
                        <InfoOutlineIcon mr="12px" mb="8px" boxSize={3} />
                      </Tooltip>
                    </Flex>
                    <Textarea
                      size="sm"
                      value={state?.cafJourney}
                      placeholder=""
                      {...register("cafJourney")}
                      onChange={(e) => {
                        setState((prevState: any) => {
                          return {
                            ...prevState,
                            cafJourney: e.target.value,
                          };
                        });
                      }}
                    />
                  </FormControl>
                  <FormControl mt={4}>
                    <Flex alignItems="center">
                      <FormLabel mr="0.5rem" fontSize="sm">
                        IT-Department
                      </FormLabel>
                      <Tooltip
                        hasArrow
                        label="How is their IT-department organized, how many personnel."
                        bg="gray.200"
                        color="gray.700"
                        placement="top-start"
                        sx={{
                          fontSize: "sm",
                          fontWeight: "light",
                          p: 2,
                          borderRadius: "md",
                          boxShadow: "md",
                        }}
                      >
                        <InfoOutlineIcon mr="12px" mb="8px" boxSize={3} />
                      </Tooltip>
                    </Flex>

                    <Textarea
                      size="sm"
                      value={state?.itDepartment}
                      placeholder=""
                      {...register("itDepartment")}
                      onChange={(e) => {
                        setState((prevState: any) => {
                          return {
                            ...prevState,
                            itDepartment: e.target.value,
                          };
                        });
                      }}
                    />
                  </FormControl>
                  <FormControl mt={4}>
                    <Flex alignItems="center">
                      <FormLabel mr="0.5rem" fontSize="sm">
                        Describe Customer
                      </FormLabel>
                      <Tooltip
                        hasArrow
                        label="Information about their business and shit."
                        bg="gray.200"
                        color="gray.700"
                        placement="top-start"
                        sx={{
                          fontSize: "sm",
                          fontWeight: "light",
                          p: 2,
                          borderRadius: "md",
                          boxShadow: "md",
                        }}
                      >
                        <InfoOutlineIcon mr="12px" mb="8px" boxSize={3} />
                      </Tooltip>
                    </Flex>

                    <Textarea
                      size="sm"
                      value={state?.describeCustomer}
                      placeholder=""
                      {...register("describeCustomer")}
                      onChange={(e) => {
                        setState((prevState: any) => {
                          return {
                            ...prevState,
                            describeCustomer: e.target.value,
                          };
                        });
                      }}
                    />
                  </FormControl>
                  <Button mt={6} onClick={handleDelete}>
                    Remove Tenantimage
                  </Button>
                </Box>
              </HStack>
            </ModalBody>

            <ModalFooter>
              <Button onClick={onClose} mr={3} fontWeight="medium">
                Cancel
              </Button>
              <Button
                isLoading={isSubmitting}
                colorScheme="orange"
                fontWeight="medium"
                type="submit"
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}
    </>
  );
};

export default EditCustomerInfoModal;
