import { BellIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Stack,
  Select,
  useToast,
  Heading,
  Text,
} from "@chakra-ui/react";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import {
  editTenantAction,
  deleteTenantAction,
} from "../../../Features/Tenants/tenantSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Action } from "../../../Models/Action";
import {
  deleteActionOnTenant,
  getAllMembers,
  updateActionOnTenant,
} from "../../../Services/CommandCenterService";
import EditTenantActionModal from "../EditTenantActionModal";
import { Table, Td, Th, Tr } from "../../Global/Table";
import { DescriptionModal } from "../../Configurations/DescriptionModal";
import { allStatuses } from "../../../Features/Config/configSlice";
import styles from "../../../Styles/Home.module.css";
import DeleteAlertDialog from "../../Global/DeleteAlertDialog";

interface IActionTableProps {
  solvedActions: Action[];
}

const SolvedActionsTable: React.FC<IActionTableProps> = ({ solvedActions }) => {
  const statuses = useAppSelector(allStatuses);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [action, setAction] = useState<Action>();
  const [description, setDescription] = useState<string>("");
  const dispatch = useAppDispatch();
  const toast = useToast();
  const [members, setMembers] = useState<{ id: string; displayName: string }[]>(
    []
  );

  const handleEdit = (action: Action) => {
    setAction(action);
  };

  const toggleModal = (text: string) => {
    setIsOpen2(true);
    setDescription(text);
  };

  const handleInlineEdit = async (newAction: Action) => {
    await updateActionOnTenant(newAction);

    dispatch(editTenantAction(newAction));

    toast({
      title: "Success!",
      description: `Action updated`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  const handleDelete = async (id: number) => {
    await deleteActionOnTenant(id);

    dispatch(deleteTenantAction(id));

    toast({
      title: "Success!",
      description: `Action deleted`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  const sortedSolvedActions = Array.from(solvedActions).sort((a, b) => {
    const dateA = new Date(a.createdAt || "");
    const dateB = new Date(b.createdAt || "");
    return dateB.getTime() - dateA.getTime();
  });

  useEffect(() => {
    action && setIsOpen(true);
  }, [action]);

  useEffect(() => {
    async function fetchGroupMembers() {
      try {
        const groupId = "cc08e2b1-8db3-45f5-8b86-bbc563a1883b"; // pt-cloudcontrol-keyvaults
        const groupMembers = await getAllMembers(groupId);
        setMembers(groupMembers);
      } catch (error) {
        console.error("Failed to retrieve group members:", error);
      }
    }

    fetchGroupMembers();
  }, []);

  return (
    <>
      {action && (
        <EditTenantActionModal
          isOpen={isOpen}
          action={action}
          onClose={() => {
            setAction(undefined);
            setIsOpen(false);
          }}
          onSave={() => {}}
        />
      )}
      {isOpen2 && (
        <DescriptionModal
          isOpen={isOpen2}
          text={description}
          onClose={() => {
            setIsOpen2(false);
            setDescription("");
          }}
        />
      )}
      <Box backgroundColor={"white"}>
        <Heading as="h3" size="md" marginBottom={4}>
          Solved Actions
        </Heading>
        <Table minW="100%">
          <thead>
            <Tr zIndex={2} className={styles.noWrap}>
              <Th>Created</Th>
              <Th>Description</Th>
              <Th>Estimate</Th>
              <Th>Status</Th>
              <Th>Category</Th>
              <Th>Assistance Required</Th>
              <Th>Assigned To</Th>
              <Th></Th>
            </Tr>
          </thead>
          <tbody>
            {/* {combinedActions?.find((a) => a.statusId === 4) && (
              <Text>Solved Actions</Text>
            )} */}
            {sortedSolvedActions.map((a, index) => {
              const isHandledByKnownUser = (handledBy: string | undefined) =>
                handledBy !== undefined &&
                members.some((m) => m.displayName === handledBy);

              return (
                <>
                  <Box as="tr" key={a.id}>
                    <Td fontSize="sm" fontWeight="medium">
                      {format(
                        new Date(a.createdAt ? a.createdAt.toString() : ""),
                        "dd.MM.yyyy @ hh:mm"
                      )}
                    </Td>
                    <Td
                      fontSize="sm"
                      style={{
                        cursor: a.shortText.length > 0 ? "pointer" : "",
                      }}
                      onClick={() => toggleModal(a.description)}
                    >
                      {a.shortText}
                    </Td>
                    <Td fontSize="sm">
                      {a.estimate !== undefined &&
                        a.estimate !== "" &&
                        a.estimate !== null &&
                        `${a.estimate}h`}
                    </Td>
                    <Td>
                      <Select
                        size="sm"
                        rounded="sm"
                        width="auto"
                        value={a.statusId}
                        onChange={(e) => {
                          const newStatusId = parseInt(e.target.value);
                          const newAction = {
                            ...a,
                            statusId: parseInt(e.target.value),
                            ...(newStatusId === 4 && {
                              handledByCustomer: true,
                            }),
                            ...(newStatusId !== 4 && {
                              handledByCustomer: false,
                            }),
                          };
                          handleInlineEdit(newAction);
                        }}
                      >
                        {statuses.map((status) => (
                          <option key={status.id} value={status.id}>
                            {status.name}
                          </option>
                        ))}
                      </Select>
                    </Td>
                    <Td fontSize="sm">{a.category?.name}</Td>
                    <Td fontSize="sm">
                      {a.assistanceRequired && (
                        <BellIcon color="red.600" boxSize={4} />
                      )}
                    </Td>
                    <Td fontSize="sm">
                      {a.handledBy && !isHandledByKnownUser(a.handledBy) ? (
                        <Text color="red.500">Unknown User: {a.handledBy}</Text>
                      ) : (
                        a.handledBy
                      )}
                    </Td>
                    <Td>
                      <Stack direction="row" justifyContent="flex-end">
                        <Button
                          onClick={() => handleEdit(a)}
                          size="xs"
                          backgroundColor="gray.600"
                          color="white"
                          fontWeight="medium"
                          _hover={{ bg: "gray.700" }}
                          _active={{
                            bg: "gray.800",
                            transform: "scale(0.95)",
                          }}
                          leftIcon={<EditIcon />}
                        >
                          Edit
                        </Button>
                        <DeleteAlertDialog
                          onDelete={() => handleDelete(a.id!)}
                          item="Action"
                          isButton={true}
                        />
                      </Stack>
                    </Td>
                  </Box>
                </>
              );
            })}
          </tbody>
        </Table>
      </Box>
    </>
  );
};

export default SolvedActionsTable;
