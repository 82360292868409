import { Box, Text, Stack, Card, Divider } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { Action } from "../../Models/Action";
import { TemplateAction } from "../../Models/TemplateAction";
import { ReviewPopup } from "./ReviewPopup";
import { Category } from "../../Models/Category";
import { useAppSelector } from "../../hooks";
import { allComments } from "../../Features/Config/configSlice";
import { statusColor, categoryIcon } from "../Global/functions";

interface TemplateActionProps {
  items: TemplateAction[];
  actions: Action[];
}

interface FormData {
  description: string;
  shortText: string;
  category: Category;
  comment: string | null;
}

const ReviewBoard: React.FC<TemplateActionProps> = ({ items, actions }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [stateItems, setStateItems] = useState(items);
  const commentList = useAppSelector(allComments);
  const [statusId, setStatusId] = useState<number>(0);

  //filters out the template actions that has the identical title as an action.
  const tmpTemplateActions = stateItems.filter(
    (templateAction) =>
      !actions.some((action) => action.shortText === templateAction.shortText)
  );

  useEffect(() => {
    setStateItems(items);
  }, [items]);

  useEffect(() => {
    setFilteredComments(
      commentList
        .filter((c) => c.actionId === actionId)
        .sort((a, b) => {
          const dateA = new Date(a.createdAt || "");
          const dateB = new Date(b.createdAt || "");
          return dateB.getTime() - dateA.getTime();
        })
    );
  }, [commentList]);

  const updateFormData = (index = 0) => {
    const newItems = tmpTemplateActions.filter(
      (templateAction) => formData.shortText !== templateAction.shortText
    );

    const newFormData = {
      description: newItems?.[index]?.description || "",
      shortText: newItems?.[index]?.shortText || "",
      category: {
        id: newItems?.[index]?.category.id || 0,
        name: newItems?.[index]?.category.name || "something",
        createdAt: newItems?.[index]?.category.createdAt || "is wrong with",
        updatedAt: newItems?.[index]?.category.updatedAt || "the first item.",
      },
      comment: newItems?.[index]?.comment || null,
    };
    setFormData(newFormData);
  };

  const [formData, setFormData] = useState<FormData>({
    description: tmpTemplateActions?.[0]?.description || "",
    shortText: tmpTemplateActions?.[0]?.shortText || "",
    category: {
      id: tmpTemplateActions?.[0]?.category.id || 0,
      name: tmpTemplateActions?.[0]?.category.name || "something",
      createdAt: tmpTemplateActions?.[0]?.category.createdAt || "is wrong with",
      updatedAt:
        tmpTemplateActions?.[0]?.category.updatedAt || "the first item.",
    },
    comment: tmpTemplateActions?.[0]?.comment || null,
  });

  const [filteredComments, setFilteredComments] =
    useState<typeof commentList>();
  const [isTemplateAction, setIsTemplateAction] = useState(true);
  // eslint-disable-next-line
  const [isActive, setIsActive] = useState<boolean>(
    tmpTemplateActions?.[0]?.isAction || false
  );

  const toggleModal = (
    shortText: string,
    description: string,
    category: Category,
    comment?: string | null,
    isAction?: boolean | false,
    statudId?: number
  ) => {
    setIsOpen(!isOpen);
    setFormData({
      shortText,
      description,
      category,
      comment: comment ?? null,
    });
    setIsActive(isAction ?? false);
    setStatusId(statudId ?? 0);
  };

  const actionsAndTemplates = [...tmpTemplateActions, ...actions];

  const sortedActionsAndTemplates = actionsAndTemplates.sort((a, b) => {
    const dateA = new Date(a.createdAt || "");
    const dateB = new Date(b.createdAt || "");
    return dateB.getTime() - dateA.getTime();
  });

  const itemsOnClick = (
    shortText,
    description,
    category,
    isAction,
    statusId
  ) => {
    setIsTemplateAction(true);
    setStatusId(statusId);
    toggleModal(shortText, description, category, isAction, statusId);
  };

  const [actionId, setActionId] = useState<number | undefined>(undefined);

  const actionsOnClick = (
    shortText,
    description,
    category,
    comment,
    isAction,
    actionId,
    statusId
  ) => {
    setIsTemplateAction(false);
    setActionId(actionId);
    if (commentList.length > 0) {
      setFilteredComments(
        commentList
          .filter((c) => c.actionId === actionId)
          .sort((a, b) => {
            const dateA = new Date(a.createdAt || "");
            const dateB = new Date(b.createdAt || "");
            return dateB.getTime() - dateA.getTime();
          })
      );
    }
    setStatusId(statusId);
    toggleModal(shortText, description, category, comment, isAction, statusId);
  };

  //set the first item of the array as the first item to be displayed
  useEffect(() => {
    setIsTemplateAction(false);
    if (sortedActionsAndTemplates.length > 0 && sortedActionsAndTemplates[0]) {
      actionsOnClick(
        sortedActionsAndTemplates[0].shortText,
        sortedActionsAndTemplates[0].description,
        sortedActionsAndTemplates[0].category,
        null,
        sortedActionsAndTemplates[0].isAction,
        sortedActionsAndTemplates[0].id, //actions id
        sortedActionsAndTemplates[0].statusId
      );
    }
  }, []);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-start"
        display="flex"
        alignItems="flex-start"
        h="52vh"
        my={4}
        minW="100%"
        spacing={4}
      >
        <Box h="100%" overflowY="auto" minW="13%" pb={0.5} pr={2}>
          <Stack spacing={1} direction="column">
            {sortedActionsAndTemplates.map(
              ({
                shortText,
                isAction,
                description,
                category,
                id,
                statusId,
              }) => {
                return (
                  <Card
                    key={id}
                    bg={!isAction ? "PTOrange.100" : "white"}
                    variant="elevated"
                    overflow="hidden"
                    mt="1"
                    lineHeight="5"
                    noOfLines={2}
                    borderColor="gray.100"
                    borderWidth="1px"
                    style={{ cursor: shortText.length > 0 ? "pointer" : "" }}
                    onClick={() =>
                      isAction
                        ? actionsOnClick(
                            shortText,
                            description,
                            category,
                            null,
                            isAction,
                            id, //actions id
                            statusId
                          )
                        : itemsOnClick(
                            shortText,
                            description,
                            category,
                            isAction,
                            statusId
                          )
                    }
                  >
                    {isAction && (
                      <>
                        <Divider
                          orientation="horizontal"
                          borderColor={
                            statusId ? statusColor(statusId) : "transparent"
                          }
                          borderWidth="5px"
                        />
                      </>
                    )}
                    <Stack
                      direction="row"
                      alignItems={"center"}
                      color={"gray.500"}
                      mt={2}
                      ml={2}
                      mb={2}
                      gap={1}
                    >
                      {categoryIcon(category)}
                      <Text fontSize={"xs"}>{category.name}</Text>
                    </Stack>
                    <Text
                      fontSize="sm"
                      fontWeight="semibold"
                      mb={2}
                      textAlign={"left"}
                      ml={2}
                    >
                      {shortText}
                    </Text>
                  </Card>
                );
              }
            )}
          </Stack>
        </Box>
        <ReviewPopup
          updateFormData={updateFormData}
          shortText={formData.shortText}
          description={formData.description}
          category={formData.category}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          isItem={isTemplateAction}
          comments={filteredComments ?? []}
          actionIdd={actionId}
          statusId={statusId}
        />
      </Stack>
    </>
  );
};

export default ReviewBoard;
