import { Avatar, Box, Tag, TagLabel, Link, Text } from "@chakra-ui/react";
import { Table, Td, Th, Tr } from "../Global/Table";
import React from "react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { GroupedAlert } from "../../Models/GroupedAlert";
//import { format } from "date-fns";

interface IAlertTableProps {
  items: GroupedAlert[];
}

const resolveColor = (severity: number) => {
  switch (Number(severity)) {
    case 3:
      return "green.200";
    case 2:
      return "yellow.200";
    case 1:
      return "orange.200";
    case 0:
      return "red.200";
    default:
      return "";
  }
};

const AlertTable: React.FC<IAlertTableProps> = ({ items }) => {
  return (
    <Table minW="100%">
      <thead>
        <Tr>
          <Th>Alert</Th>
          <Th>Title</Th>
          <Th>Description</Th>
          {/*<Th>Type</Th>*/}
          <Th>Severity</Th>
          <Th>Customer</Th>
          {/* <Th>PoC</Th> */}
          {/* <Th>Email</Th> */}
          <Th>Time</Th>
          <Th>Resource</Th>
          {/* <Th>Status</Th> */}
        </Tr>
      </thead>
      <tbody>
        {items.map((i) => (
          <Box as="tr" key={i.id}>
            <Td fontSize="sm" fontWeight="small">
              <Tag
                size="sm"
                colorScheme={"blue"}
                bg={"#e8eaf6"}
                borderRadius="full"
              >
                <Box
                  bg="#dd939f"
                  color="#181b44"
                  borderRadius="full"
                  width="1.5rem"
                  height="1.5rem"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  ml={-1}
                  mr={2}
                  mt={1}
                  mb={1}
                >
                  <Text fontSize="0.5rem">
                    {i.count <= 99 ? i.count : "99+"}
                  </Text>
                </Box>
                <TagLabel
                  maxWidth={"375px"}
                  fontSize="xs"
                  mr={1}
                  textOverflow="ellipsis"
                >
                  {i.alert}
                </TagLabel>
              </Tag>
            </Td>
            <Td fontSize="sm" fontWeight="small">
              {i.title}
            </Td>
            <Td fontSize="sm" fontWeight="small">
              {i.alertDescription}
            </Td>
            {/* <Td fontSize="sm" fontWeight="small">
              {i.alertType}
            </Td> */}
            <Td fontSize="sm" fontWeight="small">
              <Avatar
                size="xs"
                color={"#181b44"}
                bg={resolveColor(i.severity)}
                name={i.severity.toString()}
                ml={-1}
                mr={2}
                mt={1}
                mb={1}
              />
            </Td>
            <Td fontSize="sm" fontWeight="small">
              {i.customer}
            </Td>
            <Td fontSize="sm" fontWeight="small">
              {i.timeTriggered}
            </Td>
            <Td fontSize="sm" fontWeight="small">
              <Link href={i.link} isExternal>
                {i.resourceName} <ExternalLinkIcon mx="2px" />
              </Link>
            </Td>
            {/* <Td fontSize="sm" fontWeight="small">
              {i.pointOfContact}
            </Td> */}
            {/* <Td fontSize="sm" fontWeight="small">
              {i.email}
            </Td> */}
            {/* <Td fontSize="sm" fontWeight="small">
              {i.phone}
            </Td> */}
            {/*<Td fontSize="sm" fontWeight="small">
              {i.statusValue}
            </Td>*/}
          </Box>
        ))}
      </tbody>
    </Table>
  );
};

export default AlertTable;
