import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  allCategories,
  editTemplateAction,
} from "../../Features/Config/configSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { TemplateAction } from "../../Models/TemplateAction";
import { updateTemplateAction } from "../../Services/CommandCenterService";
import TinyEditor from "../Global/TinyEditor";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  templateAction: TemplateAction;
};

interface FormValues {
  description: string;
  shortText: string;
  category: string;
}

const EditTemplateActionModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onSave,
  templateAction,
}) => {
  // TODO, if needed? => const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();
  const categories = useAppSelector(allCategories);
  const toast = useToast();
  const [state, setState] = useState<TemplateAction>(templateAction);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    control,
  } = useForm<FormValues>();

  const onEditTemplate = async ({ description, shortText, category }) => {
    const findCategory = categories.find((c) => c.id === Number(category));
    if (!findCategory) {
      throw new Error("Category not found");
    }
    const newTemplate = {
      id: templateAction.id,
      description,
      shortText,
      categoryId: Number(category),
      category: findCategory,
    };

    await updateTemplateAction(newTemplate);

    dispatch(editTemplateAction(newTemplate));

    toast({
      title: "Success!",
      description: `Template updated`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    onClose();
  };

  useEffect(() => {
    isOpen && templateAction && setState(templateAction);
  }, [templateAction, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="6xl"
      blockScrollOnMount={false}
      trapFocus={false}
      returnFocusOnClose={false}
    >
      <ModalOverlay style={{ zIndex: 1280 }} />
      <ModalContent as="form" onSubmit={handleSubmit(onEditTemplate)}>
        <ModalHeader fontWeight="bold">Edit Template Action</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Stack direction={"row"} spacing={2} align={"start"}>
            <FormControl mt={4} isInvalid={!!errors.shortText}>
              <FormLabel id="shortTextLabel" htmlFor="shortText" w={"80%"}>
                Title
              </FormLabel>
              <Input
                aria-labelledby="shortTextLabel"
                value={state.shortText}
                placeholder="Template short description"
                aria-label="Template short description"
                id="shortText"
                {...register("shortText", {
                  required: "This is required",
                  maxLength: 128,
                })}
                onChange={(e) => {
                  setState((prevState: any) => {
                    return {
                      ...prevState,
                      shortText: e.target.value,
                    };
                  });
                }}
              />
              <FormErrorMessage>
                {errors.shortText?.type === "maxLength" && (
                  <span>Max 128 characters</span>
                )}
              </FormErrorMessage>
            </FormControl>
            <FormControl mt={4} isInvalid={!!errors.category} w="20%">
              <FormLabel id="categoryLabel" htmlFor="category">
                Category
              </FormLabel>
              <Select
                aria-labelledby="categoryLabel"
                title="Select a category"
                aria-label="Select a category for the template"
                value={state.categoryId}
                id="category"
                {...register("category", {
                  required: "This is required",
                })}
                onChange={(e) => {
                  setState((prevState: any) => {
                    return {
                      ...prevState,
                      categoryId: e.target.value,
                    };
                  });
                }}
                placeholder="Select category"
                width="auto"
              >
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <FormControl mt={4} isInvalid={!!errors.description}>
            <FormLabel id="descriptionLabel" htmlFor="description">
              Image Gallery
            </FormLabel>
            <Controller
              name="description"
              control={control}
              defaultValue={state.description}
              render={({ field }) => (
                <TinyEditor
                  content={field.value}
                  onContentChange={field.onChange} // onChange updates the form's state
                  editorTitle={"Description"}
                />
              )}
            />
            <FormErrorMessage>
              {errors.description &&
              typeof errors.description.message === "string"
                ? errors.description.message
                : undefined}
            </FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} mr={3} fontWeight="medium">
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            colorScheme="orange"
            fontWeight="medium"
            type="submit"
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditTemplateActionModal;
