import {
  Checkbox,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  Select,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Heading,
  InputGroup,
  InputLeftElement,
  IconButton,
  Text,
  HStack,
  Box,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import { allCategories } from "../../Features/Config/configSlice";
import { useAppSelector } from "../../hooks";
import { Action } from "../../Models/Action";
import { CustomerInfo } from "../../Models/CustomerInfo";
import { GroupedAlert } from "../../Models/GroupedAlert";
import ActiveActionsTable from "./ActionTables/ActiveActionsTable";
import AddTenantActionModal from "./AddTenantActionModal";
import AlertTable from "./AlertTable";
import EmptyState from "../Global/EmptyState";
import { TemplateAction } from "../../Models/TemplateAction";
import ReviewBoard from "./ReviewBoard";
import CustomerInfoTable from "./CustomerInfoTable";
import AddCustomerInfoModal from "./AddCustomerInfoModal";
import EditCustomerInfoModal from "./EditCustomerInfoModal";
import { Comment } from "../../Models/Comment";
import TuneIcon from "@mui/icons-material/Tune";
import { Assignment } from "../../Models/Assignment";
import AssignedComponent from "./AssignedComponent";
import SolvedActionsTable from "./ActionTables/SolvedActionsTable";

interface ITenantDetailTabsProps {
  actions: Action[];
  historicalActions: Action[];
  alerts: GroupedAlert[];
  templateActions: TemplateAction[];
  customerInfos: CustomerInfo[];
  comments: Comment[];
  assignments: Assignment[];
}

const TenantDetailTabs: React.FC<ITenantDetailTabsProps> = ({
  actions,
  historicalActions,
  alerts,
  templateActions,
  customerInfos,
  comments,
  assignments,
}) => {
  const categories = useAppSelector(allCategories);

  //State for searchbar
  const [searchKeyword, setSearchKeyword] = React.useState("");
  const handleChange = (event) => setSearchKeyword(event.target.value);

  //state for dropdown menu
  const [display, setDisplay] = React.useState("");
  const handleDisplayChange = (event) => setDisplay(event.target.value);

  const [showTemplateActions, setShowTemplateActions] = React.useState(false);

  const [showActions, setShowActions] = React.useState(true);

  const searchForTemplates = templateActions.filter((t) =>
    t.shortText.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  // Filter the arrays based on the state of the checkboxes
  const filteredTemplateActions = showTemplateActions ? searchForTemplates : [];

  //Gives the isAction true property to all actions
  const tmpActions = actions.map((action) => ({ ...action, isAction: true }));
  //const tmpTemplateActions = actions.map((action) => ({ ...action, isAction: true }));

  const searchForActions = tmpActions.filter((t) =>
    t.shortText.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  const filteredActionsReview = showActions ? searchForActions : [];

  const filteredActions = actions.filter(
    (a) =>
      a.description
        .trim()
        .toLowerCase()
        .includes(searchKeyword.trim().toLowerCase()) &&
      a.categoryId.toString().includes(display.toString())
  );

  const filteredHistoricalActions = historicalActions.filter(
    (a) =>
      a.description
        .trim()
        .toLowerCase()
        .includes(searchKeyword.trim().toLowerCase()) &&
      a.categoryId.toString().includes(display.toString())
  );

  return (
    <>
      <Tabs variant="line" padding={5} isLazy>
        <TabList mb="1em">
          <Tab _selected={{ borderColor: "#b1b7dd" }}>Customer</Tab>
          <Tab _selected={{ borderColor: "#b1b7dd" }}>Actions</Tab>
          <Tab _selected={{ borderColor: "#b1b7dd" }}>Alerts</Tab>
          <Tab _selected={{ borderColor: "#b1b7dd" }}>Reviews</Tab>
          {/*<Link
            m="2"
            ml="auto"
            isExternal
            fontSize="md"
            href="https://stcmdcmetadatadev001.z1.web.core.windows.net/index.html"
          >
            Onboarding Report
            <ExternalLinkIcon mx="4px" />
          </Link>*/}
        </TabList>
        <TabPanels bgColor="white" mt="-5" h="70vh">
          <TabPanel>
            {customerInfos.length ? (
              <>
                <Stack direction={"column"} textAlign={"left"} spacing={0}>
                  <Flex justifyContent="space-between">
                    <Stack direction="row" spacing={3} minW="50%">
                      <Heading
                        maxW="50%"
                        mt="1px"
                        size="lg"
                        fontFamily={"var(--chakra-fonts-body)"}
                        fontWeight={"var(--chakra-fontWeights-semibold)"}
                      >
                        Customer Information
                      </Heading>
                      <EditCustomerInfoModal />
                    </Stack>
                  </Flex>
                  <AssignedComponent items={assignments} />
                </Stack>
                <CustomerInfoTable items={customerInfos} />
              </>
            ) : (
              <EmptyState
                heading="You haven't added any customer information"
                text="Tap 'create infocard' to get started "
              >
                <AddCustomerInfoModal>
                  <p>Create infocard</p>
                </AddCustomerInfoModal>
              </EmptyState>
            )}
          </TabPanel>
          <TabPanel>
            <Flex mb="1em" justifyContent="space-between">
              <Stack direction="row" spacing={3} minW="50%">
                {/* <Input
                  mt="1px"
                  placeholder="Filter on description"
                  size="sm"
                  rounded="sm"
                  maxW="50%"
                  variant="outline"
                  value={searchKeyword}
                  onChange={handleChange}
                /> */}
                <Select
                  placeholder="Select category"
                  size="sm"
                  rounded="sm"
                  width="auto"
                  disabled={actions.length === 0}
                  value={display}
                  onChange={handleDisplayChange}
                >
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Select>
              </Stack>
              <AddTenantActionModal>+ Add Action</AddTenantActionModal>
            </Flex>
            <Stack direction={"column"} h="60vh" overflowY={"scroll"}>
              <Box>
                {actions.length ? (
                  <ActiveActionsTable ActiveActions={filteredActions} />
                ) : (
                  <EmptyState
                    heading="You haven't added any data"
                    text="Add an action to get started"
                  />
                )}
              </Box>
              <Box mt="6">
                {historicalActions.length ? (
                  <SolvedActionsTable
                    solvedActions={filteredHistoricalActions}
                  />
                ) : null}
              </Box>
            </Stack>
          </TabPanel>
          <TabPanel>
            <Flex mb="1em" justifyContent="space-between">
              <Stack direction="row" spacing={3} minW="50%">
                <Input
                  mt="1px"
                  placeholder="Filter on description"
                  size="sm"
                  rounded="sm"
                  maxW="50%"
                  variant="outline"
                  value={searchKeyword}
                  onChange={handleChange}
                />
                <Select
                  placeholder="Select category"
                  size="sm"
                  rounded="sm"
                  width="auto"
                  disabled={actions.length === 0}
                  value={display}
                  onChange={handleDisplayChange}
                >
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Select>
              </Stack>
            </Flex>
            {alerts.length ? (
              <AlertTable items={alerts} />
            ) : (
              <EmptyState
                heading="🤷‍♂️"
                text="No external alarms available"
              ></EmptyState>
            )}
          </TabPanel>

          {/**REVIEWS TAB */}
          <TabPanel>
            <Heading textAlign={"left"} fontSize={"2xl"} mb={4}>
              Templates and actions reviews
            </Heading>
            <Flex mb="1em" justifyContent="left">
              <Stack gap={4} position={"relative"}>
                <Stack
                  direction="row"
                  spacing={1}
                  //flex="1"
                  alignItems={"center"}
                  borderBottom={"1px solid"}
                  borderColor={"gray.200"}
                >
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <SearchIcon color="gray.500" boxSize={3} />
                    </InputLeftElement>
                    <Input
                      border={0}
                      placeholder="Search..."
                      value={searchKeyword}
                      onChange={handleChange}
                      fontSize={"xs"}
                      _focus={{
                        border: "none",
                        boxShadow: "none",
                      }}
                    />
                  </InputGroup>
                  <Menu closeOnSelect={false}>
                    <MenuButton
                      as={IconButton}
                      size="sm"
                      rounded="sm"
                      width="auto"
                      icon={<TuneIcon />}
                      bg={"white"}
                      _hover={{ bg: "white" }}
                      color={"gray.500"}
                    />
                    <MenuList minWidth="240px">
                      <MenuOptionGroup title="Filter" type="checkbox">
                        <Stack pl={1} mt={1} spacing={1}>
                          <Checkbox
                            colorScheme="blackAlpha"
                            checked={showTemplateActions}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => setShowTemplateActions(event.target.checked)}
                          >
                            Templates
                          </Checkbox>
                          <Checkbox
                            colorScheme="blackAlpha"
                            defaultChecked
                            checked={showActions}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => setShowActions(event.target.checked)}
                          >
                            Actions
                          </Checkbox>
                        </Stack>
                      </MenuOptionGroup>
                    </MenuList>
                  </Menu>
                  {/*
                <Select
                  placeholder="Select category"
                  size="sm"
                  rounded="sm"
                  width="auto"
                  disabled={actions.length === 0}
                  value={display}
                  onChange={handleDisplayChange}
                >
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Select>
                */}
                </Stack>
                {/* Shows a "No Actions" text if there are no actions and the template checkbox is not checked */}
                {filteredActionsReview.length === 0 &&
                  showTemplateActions === false &&
                  showActions === true && (
                    <Text
                      color={"gray.400"}
                      fontStyle={"italic"}
                      fontSize={12}
                      position="absolute"
                      top="200%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                    >
                      No Actions
                    </Text>
                  )}
              </Stack>
            </Flex>
            {templateActions.length || actions.length ? (
              <ReviewBoard
                items={filteredTemplateActions}
                actions={filteredActionsReview}
              />
            ) : (
              <EmptyState
                heading="You haven't added any data"
                text="Add a template to get started"
              />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default TenantDetailTabs;
