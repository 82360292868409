import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  Box,
  HStack,
  Heading,
  Textarea,
  Tooltip,
  Flex,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  activeTenant,
  addCustomerInfo,
} from "../../Features/Tenants/tenantSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Tenant } from "../../Models/Tenant";
import { CustomerInfo } from "../../Models/CustomerInfo";
import { createCustomerInfoOnTenant } from "../../Services/CommandCenterService";
import { InfoOutlineIcon } from "@chakra-ui/icons";

interface FormValues {
  azureTenantId: string;
  customerName: string;
  contactName: string;
  contactMobile: string;
  contactEmail: string;
  onboardedAt: string;
  subscriptions: number;
  customerPrice: number;
  cafJourney: string;
  itDepartment: string;
  describeCustomer: string;
}

const AddCustomerInfoModal = ({ children }) => {
  // const activeTenantSlice = useAppSelector(allTenants);
  const activeTenantObject = useAppSelector(activeTenant);
  const dispatch = useAppDispatch();

  const [state, setState] = useState<Tenant>();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>();

  const tenant = activeTenantObject?.tenant;

  const onCreateCustomerInfo = async ({
    customerName,
    contactName,
    contactMobile,
    contactEmail,
    onboardedAt,
    subscriptions,
    customerPrice,
    cafJourney,
    itDepartment,
    describeCustomer,
  }) => {
    const newCustomerInfo: CustomerInfo = {
      azureTenantId: tenant!.azureTenantId,
      customerName: customerName,
      contactName: contactName,
      contactMobile: contactMobile,
      contactEmail: contactEmail,
      onboardedAt: onboardedAt,
      subscriptions: subscriptions,
      customerPrice: customerPrice,
      cafJourney: cafJourney,
      itDepartment: itDepartment,
      describeCustomer: describeCustomer,
    };

    const result = await createCustomerInfoOnTenant(newCustomerInfo);
    const res = await result.json();

    // Update the UI with the newly created action in the database
    dispatch(addCustomerInfo(res));

    toast({
      title: "Success!",
      description: `Customerinfo created`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    onClose();
  };

  useEffect(() => {
    activeTenantObject && setState(activeTenantObject!.tenant);
  }, [activeTenantObject]);

  const shortField = "60%";

  return (
    <>
      <Button
        onClick={onOpen}
        backgroundColor="gray.900"
        color="white"
        fontWeight="medium"
        _hover={{ bg: "gray.700" }}
        _active={{
          bg: "gray.800",
          transform: "scale(0.95)",
        }}
      >
        {children}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay style={{ zIndex: 1280 }} />
        <ModalContent
          as="form"
          onSubmit={handleSubmit(onCreateCustomerInfo)}
          maxHeight="87vh"
          overflowY="auto"
        >
          {/* <ModalContent as="form"> */}
          <ModalHeader
            fontWeight="bold"
            pb="1"
            fontFamily="var(--chakra-fonts-body)"
          >
            Add Customerinformation
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Box px="1rem">
              <FormControl isInvalid={!!errors.customerName}>
                <FormLabel htmlFor="customerName" fontSize="sm">
                  Customer Name
                </FormLabel>
                <Input
                  size="sm"
                  width="30%"
                  id="customerName"
                  placeholder=""
                  defaultValue={tenant?.azureTenantName}
                  {...register("customerName", {
                    required: "This is required",
                  })}
                />
                <FormErrorMessage>
                  {errors.customerName &&
                  typeof errors.customerName.message === "string"
                    ? errors.customerName.message
                    : undefined}
                </FormErrorMessage>
              </FormControl>
            </Box>
            <HStack width="full" align="flex-start" px="1rem" pt="1rem">
              <Box flex="1" width="50%">
                <Heading
                  as="h2"
                  size="md"
                  px="0"
                  mb={2}
                  marginTop="0"
                  fontFamily="var(--chakra-fonts-body)"
                >
                  Configuration
                </Heading>

                <FormControl>
                  <FormLabel fontSize="sm">Azure Tenant Id</FormLabel>
                  <Input
                    size="sm"
                    width={shortField}
                    id="azureTenantId"
                    placeholder="00000000-0000-0000-0000-000000000000"
                    value={state?.azureTenantId}
                    {...register("azureTenantId", {
                      required: "This is required",
                    })}
                  />
                  <FormErrorMessage>
                    {errors.azureTenantId &&
                    typeof errors.azureTenantId.message === "string"
                      ? errors.azureTenantId.message
                      : undefined}
                  </FormErrorMessage>
                </FormControl>
                <Heading
                  as="h2"
                  size="md"
                  marginTop="1rem"
                  mb={2}
                  fontFamily="var(--chakra-fonts-body)"
                >
                  Contact person
                </Heading>
                <FormControl mt={4}>
                  <FormLabel fontSize="sm">Contact Name</FormLabel>
                  <Input
                    size="sm"
                    width={shortField}
                    placeholder=""
                    {...register("contactName")}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel fontSize="sm">Contact Mobile</FormLabel>
                  <Input
                    size="sm"
                    width={shortField}
                    placeholder=""
                    {...register("contactMobile")}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel fontSize="sm">Contact Email</FormLabel>
                  <Input
                    size="sm"
                    width={shortField}
                    placeholder=""
                    {...register("contactEmail")}
                  />
                </FormControl>
                <Heading
                  as="h2"
                  size="md"
                  marginTop="1rem"
                  fontFamily="var(--chakra-fonts-body)"
                >
                  Pricing
                </Heading>
                <FormControl mt={4}>
                  <Flex alignItems="center">
                    <FormLabel mr="0.5rem" fontSize="sm">
                      Customer Price
                    </FormLabel>
                    <Tooltip
                      hasArrow
                      label="Agreed price"
                      bg="gray.200"
                      color="gray.700"
                      placement="top-start"
                      sx={{
                        fontSize: "sm",
                        fontWeight: "light",
                        p: 2,
                        borderRadius: "md",
                        boxShadow: "md",
                      }}
                    >
                      <InfoOutlineIcon mr="12px" mb="8px" boxSize={3} />
                    </Tooltip>
                  </Flex>
                  <Input
                    size="sm"
                    width={shortField}
                    placeholder=""
                    {...register("customerPrice")}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <Flex alignItems="center">
                    <FormLabel mr="0.5rem" fontSize="sm">
                      Subscriptions
                    </FormLabel>
                    <Tooltip
                      hasArrow
                      label="Number of"
                      bg="gray.200"
                      color="gray.700"
                      placement="top-start"
                      sx={{
                        fontSize: "sm",
                        fontWeight: "light",
                        p: 2,
                        borderRadius: "md",
                        boxShadow: "md",
                      }}
                    >
                      <InfoOutlineIcon mr="12px" mb="8px" boxSize={3} />
                    </Tooltip>
                  </Flex>
                  <Input
                    size="sm"
                    width={shortField}
                    placeholder=""
                    {...register("subscriptions")}
                  />
                </FormControl>
              </Box>
              <Box flex="1" width="50%">
                <Heading
                  as="h2"
                  size="md"
                  marginTop="1rem"
                  mb={2}
                  fontFamily="var(--chakra-fonts-body)"
                >
                  General Information
                </Heading>
                <FormControl mt={4}>
                  <Flex alignItems="center">
                    <FormLabel mr="0.5rem" fontSize="sm">
                      Onboarded At
                    </FormLabel>
                    <Tooltip
                      hasArrow
                      label="When did we install CC?"
                      bg="gray.200"
                      color="gray.700"
                      placement="top-start"
                      sx={{
                        fontSize: "sm",
                        fontWeight: "light",
                        p: 2,
                        borderRadius: "md",
                        boxShadow: "md",
                      }}
                    >
                      <InfoOutlineIcon mr="12px" mb="8px" boxSize={3} />
                    </Tooltip>
                  </Flex>
                  <Input
                    size="sm"
                    width={shortField}
                    placeholder="yyyy-mm-dd"
                    {...register("onboardedAt")}
                  />
                </FormControl>

                <FormControl mt={4} isInvalid={!!errors.cafJourney}>
                  <Flex alignItems="center">
                    <FormLabel mr="0.5rem" fontSize="sm">
                      CAF Journey
                    </FormLabel>
                    <Tooltip
                      hasArrow
                      label="Regarding their cloud journey, describe where they are, what are their goals (hybrid, cloud only, how big on-premise)."
                      bg="gray.200"
                      color="gray.700"
                      placement="top-start"
                      sx={{
                        fontSize: "sm",
                        fontWeight: "light",
                        p: 2,
                        borderRadius: "md",
                        boxShadow: "md",
                      }}
                    >
                      <InfoOutlineIcon mr="12px" mb="8px" boxSize={3} />
                    </Tooltip>
                  </Flex>
                  <Textarea
                    size="sm"
                    width={shortField}
                    placeholder=""
                    {...register("cafJourney", {
                      maxLength: 422,
                    })}
                  />
                  <FormErrorMessage>
                    {errors.cafJourney?.type === "maxLength" && (
                      <span>Max 422 characters</span>
                    )}
                  </FormErrorMessage>
                </FormControl>
                <FormControl mt={4} isInvalid={!!errors.itDepartment}>
                  <Flex alignItems="center">
                    <FormLabel mr="0.5rem" fontSize="sm">
                      IT-Department
                    </FormLabel>
                    <Tooltip
                      hasArrow
                      label="How is their IT-department organized, how many personnel."
                      bg="gray.200"
                      color="gray.700"
                      placement="top-start"
                      sx={{
                        fontSize: "sm",
                        fontWeight: "light",
                        p: 2,
                        borderRadius: "md",
                        boxShadow: "md",
                      }}
                    >
                      <InfoOutlineIcon mr="12px" mb="8px" boxSize={3} />
                    </Tooltip>
                  </Flex>
                  <Textarea
                    size="sm"
                    width={shortField}
                    placeholder=""
                    {...register("itDepartment", {
                      maxLength: 422,
                    })}
                  />
                  <FormErrorMessage>
                    {errors.itDepartment?.type === "maxLength" && (
                      <span>Max 422 characters</span>
                    )}
                  </FormErrorMessage>
                </FormControl>
                <FormControl mt={4} isInvalid={!!errors.describeCustomer}>
                  <Flex alignItems="center">
                    <FormLabel mr="0.5rem" fontSize="sm">
                      Describe Customer
                    </FormLabel>
                    <Tooltip
                      hasArrow
                      label="Information about their business and shit."
                      bg="gray.200"
                      color="gray.700"
                      placement="top-start"
                      sx={{
                        fontSize: "sm",
                        fontWeight: "light",
                        p: 2,
                        borderRadius: "md",
                        boxShadow: "md",
                      }}
                    >
                      <InfoOutlineIcon mr="12px" mb="8px" boxSize={3} />
                    </Tooltip>
                  </Flex>
                  <Textarea
                    size="sm"
                    width={shortField}
                    placeholder=""
                    {...register("describeCustomer", {
                      maxLength: 422,
                    })}
                  />
                  <FormErrorMessage>
                    {errors.describeCustomer?.type === "maxLength" && (
                      <span>Max 422 characters</span>
                    )}
                  </FormErrorMessage>
                </FormControl>
              </Box>
            </HStack>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} mr={3} fontWeight="medium">
              Cancel
            </Button>
            <Button
              isLoading={isSubmitting}
              backgroundColor="#99FFFE"
              color="#194D4C"
              fontWeight="medium"
              type="submit"
            >
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddCustomerInfoModal;
