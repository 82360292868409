import { Flex, Heading } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/spinner";
import React from "react";

interface ILoadingMessageProps {
  message: string;
  size: "xs" | "sm" | "md" | "lg" | "xl";
}

const LoadingMessage: React.FC<ILoadingMessageProps> = ({ message, size }) => {
  return (
    <Flex
      width="100%"
      backgroundColor="white"
      borderRadius="8px"
      p={16}
      justify="center"
      align="center"
      direction="column"
    >
      <Spinner size={size} />
      <Heading size="sm" mt={2}>
        {message}
      </Heading>
    </Flex>
  );
};

export default LoadingMessage;
