export const tableStyle = {
  width: "100%",
  borderCollapse: "collapse",
  borderSpacing: 0,
  backgroundColor: "#fff",
  //border: '1px solid #ccc',
  "th, td": {
    padding: "8px",
    border: "1px solid #ccc",
    textAlign: "left",
  },
  thead: {
    backgroundColor: "#f4f4f4",
  },
};

export const contentStyle = {
  fontFamily: "Helvetica, Arial, sans-serif",
  // Heading Styles
  h1: {
    fontSize: "2em",
    marginTop: "0.67em",
    marginBottom: "0.67em",
    fontWeight: "600",
  },
  h2: {
    fontSize: "1.5em",
    marginTop: "0.83em",
    marginBottom: "0.83em",
    fontWeight: "600",
  },
  h3: {
    fontSize: "1.17em",
    marginTop: "1em",
    marginBottom: "1em",
    fontWeight: "600",
  },
  h4: {
    fontSize: "1em",
    marginTop: "1.33em",
    marginBottom: "1.33em",
    fontWeight: "600",
  },
  h5: {
    fontSize: "0.83em",
    marginTop: "1.67em",
    marginBottom: "1.67em",
    fontWeight: "600",
  },
  h6: {
    fontSize: "0.67em",
    marginTop: "2.33em",
    marginBottom: "2.33em",
    fontWeight: "600",
  },
  // List Styles
  ul: {
    marginLeft: "40px",
    paddingLeft: "0",
  },
  ol: {
    marginLeft: "40px",
    paddingLeft: "0",
  },
  li: {
    marginBottom: "0.5em",
  },
  // Combine table styles from other export
  ...tableStyle,
};
