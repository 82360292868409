import React from "react";
import TinyEditor from "../Global/TinyEditor";
import { useAppDispatch } from "../../hooks";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { WikiPage } from "../../Models/WikiPage";
import { createWikiPage } from "../../Services/CommandCenterService";
import { addWikiPage } from "../../Features/WikiPages/wikiSlice";
import { msalInstance } from "../../AppAuth";

interface FormValues {
  title: string;
  content: string;
  createdBy: string;
}

const AddWikiPageModal = ({ children }) => {
  const dispatch = useAppDispatch();

  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => {
      //reset the form when the modal is closed
      reset();
    },
  });
  const toast = useToast();
  const activeAccount = msalInstance.getActiveAccount();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
    control,
  } = useForm<FormValues>();

  const onCreatePage = async ({ title, content }) => {
    const newPage: WikiPage = {
      title: title,
      content: content,
      createdBy: activeAccount?.name || "No Author Found",
    };

    const result = await createWikiPage(newPage);
    const res = await result.json();

    //Update the UI with the new page
    dispatch(addWikiPage(res));

    toast({
      title: "Success!",
      description: `The new page was created.`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    reset();

    onClose();
  };

  return (
    <>
      <Button
        onClick={onOpen}
        size="sm"
        backgroundColor="gray.900"
        color="white"
        fontWeight="medium"
        _hover={{ bg: "gray.700" }}
        _active={{
          bg: "gray.800",
          transform: "scale(0.95)",
        }}
      >
        {children}
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="6xl"
        blockScrollOnMount={false}
        trapFocus={false}
        returnFocusOnClose={false}
      >
        <ModalOverlay style={{ zIndex: 1280 }} />
        <ModalContent as="form" onSubmit={handleSubmit(onCreatePage)}>
          <ModalHeader fontWeight="bold">Add new page</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={0}>
            <FormControl isInvalid={!!errors.title}>
              <FormLabel>Title</FormLabel>
              <Input
                placeholder="Enter title"
                id="title"
                {...register("title", {
                  required: "This is required",
                  maxLength: { value: 40, message: "Max length should be 40" },
                })}
              />
              <FormErrorMessage>
                {errors.title && typeof errors.title.message === "string"
                  ? errors.title.message
                  : undefined}
              </FormErrorMessage>
              {/*<Heading size="sm" mt={4}>
                Select an image
              </Heading>
              <ImageGallery
                onImageChanged={(image) => {
                  setSelectedImageUrl(image);
                }}
              />*/}
              <FormControl mt={4} isInvalid={!!errors.content}></FormControl>
              <FormLabel mt={4}>Image Gallery</FormLabel>
              <Controller
                name="content"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TinyEditor
                    content={field.value}
                    onContentChange={field.onChange} // onChange updates the form's state
                    editorTitle="Content"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.content && typeof errors.content.message === "string"
                  ? errors.content.message
                  : undefined}
              </FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} mr={3} fontWeight="medium">
              Cancel
            </Button>
            <Button
              isLoading={isSubmitting}
              colorScheme="orange"
              fontWeight="medium"
              type="submit"
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddWikiPageModal;
