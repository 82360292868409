import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { allCategories, allStatuses } from "../../Features/Config/configSlice";
import { editTenantAction } from "../../Features/Tenants/tenantSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Action } from "../../Models/Action";
import {
  getAllMembers,
  updateActionOnTenant,
} from "../../Services/CommandCenterService";
import TinyEditor from "../Global/TinyEditor";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  action: Action;
};

interface FormValues {
  description: string;
  shortText: string;
  category: string;
  handledByCustomer: boolean;
  assistanceRequired: boolean;
  estimate: number;
  status: string;
  handledBy: string;
}

const EditTenantActionModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onSave,
  action,
}) => {
  // TODO, if needed? => const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();
  const categories = useAppSelector(allCategories);
  const statuses = useAppSelector(allStatuses);
  const toast = useToast();
  const [state, setState] = useState<Action>(action);
  const [members, setMembers] = useState<{ id: string; displayName: string }[]>(
    []
  );

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    control,
  } = useForm<FormValues>();

  const onEditAction = async ({
    description,
    shortText,
    category,
    handledByCustomer,
    assistanceRequired,
    estimate,
    status,
    handledBy,
  }) => {
    const foundCategory = categories.find((c) => c.id === Number(category));
    const foundStatus = statuses.find((s) => s.id === Number(status));

    if (!foundCategory) {
      console.error("Category or Status not found");
      // Handle the error
      return;
    }
    if (foundCategory) {
      console.log("Category found: ", foundCategory);
    }

    if (!foundStatus) {
      console.error("Status not found");
      // Handle the error
      return;
    }
    if (foundStatus) {
      console.log("Status found: ", foundStatus);
    }

    const newAction = {
      id: action.id,
      azureTenantId: action.azureTenantId,
      tenantId: action.tenantId,
      description,
      shortText,
      categoryId: Number(category),
      category: foundCategory,
      handledByCustomer,
      handledBy,
      assistanceRequired,
      estimate,
      statusId: Number(status),
      status: foundStatus,
      createdAt: action.createdAt,
      isGlobal: action.isGlobal,
    };

    await updateActionOnTenant(newAction);

    dispatch(editTenantAction(newAction));

    toast({
      title: "Success!",
      description: `Action updated`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    onClose();
  };

  useEffect(() => {
    isOpen && action && setState(action);
  }, [action, isOpen]);

  useEffect(() => {
    async function fetchGroupMembers() {
      try {
        //TODO: Make this secret
        const groupId = "cc08e2b1-8db3-45f5-8b86-bbc563a1883b"; // pt-cloudcontrol-keyvaults
        const groupMembers = await getAllMembers(groupId);
        setMembers(groupMembers);
      } catch (error) {
        console.error("Failed to retrieve group members:", error);
      }
    }
    fetchGroupMembers();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="6xl"
      blockScrollOnMount={false}
      trapFocus={false}
      returnFocusOnClose={false}
    >
      <ModalOverlay style={{ zIndex: 1280 }} />
      <ModalContent as="form" onSubmit={handleSubmit(onEditAction)}>
        <ModalHeader fontWeight="bold">Edit Action</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Stack direction={"row"} spacing={2} align={"start"}>
            <FormControl isInvalid={!!errors.shortText} w="60%">
              <FormLabel>Title</FormLabel>
              <Input
                value={state.shortText}
                placeholder="Action short description"
                id="shortText"
                {...register("shortText", {
                  required: "This is required",
                  maxLength: 128,
                })}
                onChange={(e) => {
                  setState((prevState: any) => {
                    return {
                      ...prevState,
                      shortText: e.target.value,
                    };
                  });
                }}
              />
              <FormErrorMessage>
                {errors.shortText?.type === "maxLength" && (
                  <span>Max 128 characters</span>
                )}
              </FormErrorMessage>
            </FormControl>
            <FormControl w="20%">
              <FormLabel htmlFor="status">Status</FormLabel>
              <Select
                value={state.statusId}
                id="status"
                {...register("status")}
                onChange={(e) => {
                  setState((prevState: any) => {
                    return {
                      ...prevState,
                      statusId: e.target.value,
                    };
                  });
                }}
                placeholder="Select status"
                width="auto"
              >
                {statuses.map((status) => (
                  <option key={status.id} value={status.id}>
                    {status.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl isInvalid={!!errors.category} w="20%">
              <FormLabel htmlFor="category">Category</FormLabel>
              <Select
                value={state.categoryId}
                id="category"
                {...register("category", {
                  required: "This is required",
                })}
                onChange={(e) => {
                  setState((prevState: any) => {
                    return {
                      ...prevState,
                      categoryId: e.target.value,
                    };
                  });
                }}
                placeholder="Select category"
                width="auto"
              >
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <FormControl mt={4} isInvalid={!!errors.description}>
            <FormLabel>Image Gallery</FormLabel>
            <Controller
              name="description"
              control={control}
              defaultValue={state.description}
              render={({ field }) => (
                <TinyEditor
                  content={field.value}
                  onContentChange={field.onChange} // onChange updates the form's state
                  editorTitle="Description"
                />
              )}
            />
            <FormErrorMessage>
              {errors.description &&
              typeof errors.description.message === "string"
                ? errors.description.message
                : undefined}
            </FormErrorMessage>
          </FormControl>
          <Stack direction={"row"} mt={4} spacing={2} align={"start"} mx={2}>
            <FormControl isInvalid={!!errors.estimate}>
              <FormLabel>Estimate</FormLabel>
              <Input
                value={state.estimate}
                placeholder=""
                id="estimate"
                {...register("estimate", {
                  maxLength: 8,
                })}
                onChange={(e) => {
                  setState((prevState: any) => {
                    return {
                      ...prevState,
                      estimate: e.target.value,
                    };
                  });
                }}
              />
              <FormErrorMessage>
                {errors.estimate?.type === "maxLength" && (
                  <span>Max 8 characters</span>
                )}
              </FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel>Assigned To</FormLabel>
              <Select
                value={state.handledBy}
                id="handledBy"
                {...register("handledBy")}
                onChange={(e) => {
                  setState((prevState: any) => {
                    return {
                      ...prevState,
                      handledBy: e.target.value,
                    };
                  });
                }}
                placeholder="Select consultant"
                width="auto"
              >
                {members.map((m) => (
                  <option key={m.id} value={m.displayName}>
                    {m.displayName}
                  </option>
                ))}
              </Select>
            </FormControl>
            {/*<FormControl mt={4}>
              <FormLabel>Solved</FormLabel>
              <Switch
                isChecked={state.handledByCustomer}
                id="handledByCustomer"
                colorScheme="green"
                {...register("handledByCustomer")}
                onChange={(e) => {
                  setState((prevState: any) => {
                    return {
                      ...prevState,
                      handledByCustomer: e.target.checked,
                    };
                  });
                }}
              ></Switch>
              </FormControl>*/}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} mr={3} fontWeight="medium">
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            colorScheme="orange"
            fontWeight="medium"
            type="submit"
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditTenantActionModal;
