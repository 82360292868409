import React, { useRef, useState } from "react";
import { uploadFile } from "../../Services/CommandCenterService";
import { Box, Button, Input, Stack, useToast } from "@chakra-ui/react";
import { UploadFile } from "@mui/icons-material";

interface FileUploadProps {
  filename?: string;
  onlyButton?: boolean;
}

const FileUpload: React.FC<FileUploadProps> = ({
  filename,
  onlyButton = false,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [dynamicFilename, setDynamicFilename] = useState("");
  const [uploadError, setUploadError] = useState<string | null>(null);
  const toast = useToast();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUploadError(null);
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      try {
        const result = await uploadFile(selectedFile, filename!);
        console.log("File uploaded successfully:", result);
        setUploadError(null);
      } catch (error: any) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const triggerFileInputClick = () => {
    setUploadError(null);
    fileInputRef.current?.click();
  };

  const handleButtonFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setDynamicFilename(event.target.files[0].name);
      if (onlyButton) {
        try {
          const result = await uploadFile(
            event.target.files[0],
            event.target.files[0].name
          );
          if (result.error) {
            setUploadError(result.status);
            toast({
              title: "Ups!",
              description: result.status,
              status: "error",
              duration: 6500,
              isClosable: true,
            });
          } else {
            setUploadError(null);
            toast({
              title: "Success!",
              description: "File uploaded successfully",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            console.log("File uploaded successfully:", result);
          }
        } catch (error: any) {
          console.error("Error uploading file:", error);
          setUploadError("An error occurred during upload. Please try again.");
        }
      }
    }
  };

  if (onlyButton) {
    return (
      <>
        <Box>
          <Input
            type="file"
            hidden
            onChange={handleButtonFileChange}
            border={0}
            ref={fileInputRef}
          />
          <Button
            aria-label="Upload Image"
            onClick={triggerFileInputClick}
            variant="solid"
            leftIcon={<UploadFile />}
            colorScheme="orange"
          >
            Upload Image
          </Button>
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Box>
          <Stack gap={2}>
            <Input type="file" onChange={handleFileChange} border={0} />
            <Button
              aria-label="Upload tenant logo"
              onClick={handleUpload}
              variant="solid"
              leftIcon={<UploadFile />}
            >
              Upload tenant logo
            </Button>
          </Stack>
        </Box>
      </>
    );
  }
};

export default FileUpload;
