import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "@chakra-ui/react";

export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    const currentAccount = instance.getActiveAccount();
    instance.logoutRedirect({ account: currentAccount });
  };

  return (
    <Button
      onClick={handleLogout}
      bg="transparent"
      _hover={{ bg: "transparent" }}
      _active={{ bg: "transparent" }}
      _focus={{ boxShadow: "none" }}
    >
      Sign Out
    </Button>
  );
};

export default SignOutButton;
