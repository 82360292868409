import { Box, Button } from "@chakra-ui/react";
import { Table, Td, Th, Tr } from "../Global/Table";
import React, { useEffect, useState } from "react";
import { Category } from "../../Models/Category";
import { EditIcon } from "@chakra-ui/icons";
import EditCategoryModal from "./EditCategoryModal";

interface CategoryTableProps {
  items: Category[];
}

const CategoryTable: React.FC<CategoryTableProps> = ({ items }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [category, setCategory] = useState<Category>();
  const handleEdit = (category: Category) => {
    setCategory(category);
  };

  useEffect(() => {
    category && setIsOpen(true);
  }, [category]);

  return (
    <>
      {category && (
        <EditCategoryModal
          isOpen={isOpen}
          category={category}
          onClose={() => {
            setCategory(undefined);
            setIsOpen(false);
          }}
          onSave={() => {}}
        />
      )}

      <Table minW="100%">
        <thead>
          <Tr>
            <Th>Id</Th>
            <Th>Name</Th>
            <Th></Th>
          </Tr>
        </thead>
        <tbody>
          {items.map((i) => (
            <Box as="tr" key={i.id}>
              <Td fontSize="sm" fontWeight="small">
                {i.id}
              </Td>
              <Td fontSize="sm" fontWeight="small">
                {i.name}
              </Td>
              <Td>
                <Button
                  onClick={() => handleEdit(i)}
                  size="xs"
                  backgroundColor="gray.600"
                  color="white"
                  fontWeight="medium"
                  _hover={{ bg: "gray.700" }}
                  _active={{
                    bg: "gray.800",
                    transform: "scale(0.95)",
                  }}
                  leftIcon={<EditIcon />}
                >
                  Edit
                </Button>
              </Td>
            </Box>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default CategoryTable;
