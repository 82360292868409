import { Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";

interface IEmptyStateProps {
  heading: string;
  text: string;
  children?: React.ReactNode;
}

const EmptyState: React.FC<IEmptyStateProps> = ({
  heading,
  text,
  children,
}) => {
  return (
    <Flex
      width="100%"
      backgroundColor="white"
      borderRadius="8px"
      p={16}
      justify="center"
      align="center"
      direction="column"
    >
      <Heading size="lg" mb={2}>
        {heading}
      </Heading>
      <Text mb={4}>{text}</Text>
      {children}
    </Flex>
  );
};

export default EmptyState;
