import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { WikiPage } from "../../Models/WikiPage";
import { msalInstance } from "../../AppAuth";
import { useAppDispatch } from "../../hooks";
import { useForm, Controller } from "react-hook-form";
import { updateWikiPage } from "../../Services/CommandCenterService";
import { editWikiPage } from "../../Features/WikiPages/wikiSlice";
import TinyEditor from "../Global/TinyEditor";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  wikiPage: WikiPage;
};

interface FormValues {
  title: string;
  content: string;
  modifiedBy: string;
}

const EditWikiPageModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onSave,
  wikiPage,
}) => {
  const activeAccount = msalInstance.getActiveAccount();
  const dispatch = useAppDispatch();
  const toast = useToast();
  const [state, setState] = useState<WikiPage>(wikiPage);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    control,
  } = useForm<FormValues>();

  const onEditWikiPage = async ({ title, content }) => {
    const newWikiPage: WikiPage = {
      id: wikiPage.id,
      title,
      content,
      modifiedBy: activeAccount?.name || "No Author Found",
      createdBy: wikiPage.createdBy,
      createdAt: wikiPage.createdAt,
    };

    await updateWikiPage(newWikiPage);

    dispatch(editWikiPage(newWikiPage));

    toast({
      title: "Success!",
      description: `Wiki Page updated`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    onClose();
  };

  useEffect(() => {
    isOpen && wikiPage && setState(wikiPage);
  }, [wikiPage, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="6xl"
      blockScrollOnMount={false}
      trapFocus={false}
      returnFocusOnClose={false}
    >
      <ModalOverlay style={{ zIndex: 1280 }} />
      <ModalContent
        as="form"
        onSubmit={handleSubmit(onEditWikiPage)}
        //maxW={modalMaxWidth}
      >
        <ModalHeader fontWeight="bold">Edit Page</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={0}>
          <FormControl isInvalid={!!errors.title}>
            <FormLabel>Title</FormLabel>
            <Input
              value={state.title}
              placeholder="Page Title"
              id="title"
              {...register("title", {
                required: "This is required",
                maxLength: { value: 40, message: "Max length should be 40" },
              })}
              onChange={(e) => {
                setState((prevState: any) => {
                  return {
                    ...prevState,
                    title: e.target.value,
                  };
                });
              }}
            />
            <FormErrorMessage>
              {errors.title && typeof errors.title.message === "string"
                ? errors.title.message
                : undefined}
            </FormErrorMessage>
          </FormControl>
          {/*<Heading size="sm" mt={4}>
            Select an image
          </Heading>
          <ImageGallery
            onImageChanged={(image) => {
              setSelectedImageUrl(image);
            }}
          />*/}
          <FormControl mt={4} isInvalid={!!errors.content} minHeight={"60vh"}>
            <FormLabel>Image Gallery</FormLabel>
            <Controller
              name="content"
              control={control}
              defaultValue={state.content}
              render={({ field }) => (
                <TinyEditor
                  //imageUrl={selectedImageUrl}
                  content={field.value}
                  onContentChange={field.onChange} // onChange updates the form's state
                  editorTitle="Content"
                />
              )}
            />
            <FormErrorMessage>
              {errors.content && typeof errors.content.message === "string"
                ? errors.content.message
                : undefined}
            </FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} mr={3} fontWeight="medium">
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            colorScheme="orange"
            fontWeight="medium"
            type="submit"
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditWikiPageModal;
