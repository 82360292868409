import React, { useEffect, useState } from "react";
import EmptyState from "../Global/EmptyState";
import TenantTableSkeleton from "./TenantTableSkeleton";
import TenantTable from "./TenantTable";
import TenantTableHeader from "./TenantTableHeader";
import {
  allTenants,
  setTenants,
  allAssignments,
  setAssignments,
  activeTenantActions,
} from "../../Features/Tenants/tenantSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getAllTenants,
  getAllAssignments,
} from "../../Services/CommandCenterService";
import AddTenantModal from "./AddTenantModal";

const Tenants: React.FC = () => {
  const [loading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const tenants = useAppSelector(allTenants);
  const assignments = useAppSelector(allAssignments);
  const actions = useAppSelector(activeTenantActions);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getAllTenants();
      const dataAssignments = await getAllAssignments();
      dispatch(setTenants(data));
      dispatch(setAssignments(dataAssignments));
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch]);

  if (loading) {
    return (
      <>
        <TenantTableHeader />
        <TenantTableSkeleton />
      </>
    );
  }

  return (
    <>
      <TenantTableHeader />

      {tenants.length ? (
        <TenantTable tenants={tenants} assignments={assignments} />
      ) : (
        <EmptyState
          heading="You haven't added any tenants."
          text="Let's get started"
        >
          <AddTenantModal>Add your first tenant</AddTenantModal>
        </EmptyState>
      )}
    </>
  );
};

export default Tenants;
