import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { editCategory } from "../../Features/Config/configSlice";
import { useAppDispatch } from "../../hooks";
import { Category } from "../../Models/Category";
import { updateCategory } from "../../Services/CommandCenterService";
import { modalMaxWidth } from "../Global/ModalSize";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  category: Category;
};

interface FormValues {
  name: string;
}

const EditCategoryModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onSave,
  category,
}) => {
  const dispatch = useAppDispatch();
  const [state, setState] = useState<Category>(category);
  const toast = useToast();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>();

  const onEditCategory = async ({ name }) => {
    const newCategory: Category = {
      id: category.id,
      name,
    };

    await updateCategory(newCategory);

    dispatch(editCategory(newCategory));

    toast({
      title: "Success!",
      description: `Category updated`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    onClose();
  };

  useEffect(() => {
    isOpen && category && setState(category);
  }, [category, isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay style={{ zIndex: 1280 }} />
      <ModalContent
        as="form"
        onSubmit={handleSubmit(onEditCategory)}
        maxW={modalMaxWidth}
      >
        <ModalHeader fontWeight="bold">Edit Category</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl isInvalid={!!errors.name}>
            <FormLabel>Name</FormLabel>
            <Input
              value={state.name}
              placeholder="Category name"
              id="name"
              {...register("name", {
                required: "This is required",
              })}
              onChange={(e) => {
                setState((prevState: any) => {
                  return {
                    ...prevState,
                    name: e.target.value,
                  };
                });
              }}
            />
            <FormErrorMessage>
              {errors.name && typeof errors.name.message === "string"
                ? errors.name.message
                : undefined}
            </FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} mr={3} fontWeight="medium">
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            backgroundColor="#99FFFE"
            color="#194D4C"
            fontWeight="medium"
            type="submit"
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditCategoryModal;
