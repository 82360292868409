import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "../../hooks";
import {
  createAssignment,
  getAllAssignments,
  getAllMembers,
} from "../../Services/CommandCenterService";
import { modalMaxWidth } from "../Global/ModalSize";
import { Assignment } from "../../Models/Assignment";
import { addAssignment } from "../../Features/Tenants/tenantSlice";
import { Tenant } from "../../Models/Tenant";

interface FormValues {
  userId: string;
  azureTenantId: string;
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  tenant: Tenant;
};

const AddAssignmentModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onSave,
  tenant,
}) => {
  const dispatch = useAppDispatch();
  const [members, setMembers] = useState<{ id: string; displayName: string }[]>(
    []
  );

  const toast = useToast();
  const [state, setState] = useState<Tenant>(tenant);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>();

  const onCreateAssignment = async ({ userId, azureTenantId }) => {
    const newAssignment: Assignment = {
      userId,
      azureTenantId: tenant.azureTenantId,
    };

    const result = await createAssignment(newAssignment);
    const res = await result.json();

    dispatch(addAssignment(res));

    toast({
      title: "Success!",
      description: `Consultant assigned to tenant`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    onClose();
  };

  useEffect(() => {
    async function fetchGroupMembers() {
      try {
        //TODO: Make this secret
        const groupId = "cc08e2b1-8db3-45f5-8b86-bbc563a1883b"; // pt-cloudcontrol-keyvaults
        const groupMembers = await getAllMembers(groupId);
        const allAssignments = await getAllAssignments();
        // Filter members who do not have an existing assignment for the current tenant
        const filteredMembers = groupMembers.filter(
          (member) =>
            !allAssignments.some(
              (assignment) =>
                assignment.userId === member.id &&
                assignment.azureTenantId === tenant.azureTenantId
            )
        );
        setMembers(filteredMembers);
      } catch (error) {
        console.error("Failed to retrieve group members:", error);
      }
    }

    fetchGroupMembers();
  }, [isOpen, tenant.azureTenantId]);

  useEffect(() => {
    isOpen && tenant && setState(tenant);
  }, [tenant, isOpen]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay style={{ zIndex: 1280 }} />
        <ModalContent
          as="form"
          onSubmit={handleSubmit(onCreateAssignment)}
          maxW={modalMaxWidth}
        >
          {/* <ModalContent as="form"> */}
          <ModalHeader fontWeight="bold">Add Action</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl isInvalid={!!errors.userId}>
              <FormLabel>Name</FormLabel>
              <Select
                id="userId"
                {...register("userId", {
                  required: "This is required",
                })}
                placeholder="Select consultant"
                width="auto"
              >
                {members.map((m) => (
                  <option key={m.id} value={m.id}>
                    {m.displayName}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>
                {errors.userId && typeof errors.userId.message === "string"
                  ? errors.userId.message
                  : undefined}
              </FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} mr={3} fontWeight="medium">
              Cancel
            </Button>
            <Button
              isLoading={isSubmitting}
              colorScheme="orange"
              fontWeight="medium"
              type="submit"
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddAssignmentModal;
