import { EditIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { activeTenant, editTenant } from "../../Features/Tenants/tenantSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Tenant } from "../../Models/Tenant";
import { updateTenant } from "../../Services/CommandCenterService";
import { modalMaxWidth } from "../Global/ModalSize";

interface FormValues {
  azureTenantName: string;
  azureTenantId: string;
  azureAnalyticsId: string;
  powerBIReportId: string;
  powerBIUsageReportId: string;
  active: boolean;
  crmCustomerId: string;
  customerShortName: string;
}

const EditTenantModal = () => {
  const activeTenantObject = useAppSelector(activeTenant);

  const initialState = {
    azureTenantName: activeTenantObject!.tenant.azureTenantName,
    azureTenantId: activeTenantObject!.tenant.azureTenantId,
    azureAnalyticsId: activeTenantObject?.tenant.azureAnalyticsId,
    powerBIReportId: activeTenantObject?.tenant.powerBIReportId,
    powerBIUsageReportId: activeTenantObject?.tenant.powerBIUsageReportId,
    active: activeTenantObject!.tenant.active,
    crmCustomerId: activeTenantObject?.tenant.crmCustomerId,
    customerShortName: activeTenantObject!.tenant.customerShortName,
  };

  const dispatch = useAppDispatch();

  const [state, setState] = useState<Tenant>();

  const toast = useToast();

  // const { register, handleSubmit, control, errors } = useForm<IFormControl>();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>();

  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => {
      //reset the form when the modal is closed
      reset(initialState);
      setState(initialState);
    },
  });

  const onEditTenant = async ({
    azureTenantName,
    azureTenantId,
    azureAnalyticsId,
    powerBIReportId,
    powerBIUsageReportId,
    active,
    crmCustomerId,
    customerShortName,
  }) => {
    const tenant: Tenant = {
      ...state,
      active,
      azureTenantId,
      azureTenantName,
      azureAnalyticsId,
      powerBIReportId,
      powerBIUsageReportId,
      crmCustomerId,
      customerShortName,
    };
    try {
      // Attempt to update the tenant via API call
      await updateTenant(tenant);

      // Dispatch the update to Redux store
      dispatch(editTenant(tenant));

      // Show success toast notification
      toast({
        title: "Success!",
        description: `Tenant updated successfully`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Close the modal
      onClose();
    } catch (error) {
      // Show error toast notification
      toast({
        title: "Error",
        description: `Failed to update tenant: ${
          (error as Error).message || "Unknown error"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      // Log the error for debugging purposes
      console.error("Failed to update tenant", error);
    }
  };

  useEffect(() => {
    activeTenantObject && setState(activeTenantObject!.tenant);
    reset && reset(initialState);
  }, [activeTenantObject, reset, isOpen]);

  return (
    <>
      <Button
        onClick={onOpen}
        backgroundColor="gray.900"
        color="white"
        fontWeight="medium"
        _hover={{ bg: "gray.700" }}
        _active={{
          bg: "gray.800",
          transform: "scale(0.95)",
        }}
        leftIcon={<EditIcon />}
      >
        Edit
      </Button>
      {state ? (
        <Modal isOpen={isOpen} onClose={onClose} size="lg">
          <ModalOverlay style={{ zIndex: 1280 }} />
          <ModalContent
            as="form"
            onSubmit={handleSubmit(onEditTenant)}
            maxW={modalMaxWidth}
          >
            {/* <ModalContent as="form"> */}
            <ModalHeader fontWeight="bold">Edit Tenant</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl isInvalid={!!errors.azureTenantName}>
                <FormLabel htmlFor="azureTenantName">Tenant</FormLabel>
                <Input
                  value={state?.azureTenantName}
                  id="azureTenantName"
                  placeholder=""
                  {...register("azureTenantName", {
                    required: "This is required",
                  })}
                  onChange={(e) => {
                    setState((prevState: any) => {
                      return {
                        ...prevState,
                        azureTenantName: e.target.value,
                      };
                    });
                  }}
                />
                <FormErrorMessage>
                  {errors.azureTenantName &&
                  typeof errors.azureTenantName.message === "string"
                    ? errors.azureTenantName.message
                    : undefined}
                </FormErrorMessage>
              </FormControl>

              <FormControl mt={4} isInvalid={!!errors.azureTenantId}>
                <FormLabel>Azure Tenant Id</FormLabel>
                <Input
                  value={state?.azureTenantId}
                  id="azureTenantId"
                  placeholder="00000000-0000-0000-0000-000000000000"
                  {...register("azureTenantId", {
                    required: "This is required",
                  })}
                  onChange={(e) => {
                    setState((prevState: any) => {
                      return {
                        ...prevState,
                        azureTenantId: e.target.value,
                      };
                    });
                  }}
                />
                <FormErrorMessage>
                  {errors.azureTenantId &&
                  typeof errors.azureTenantId.message === "string"
                    ? errors.azureTenantId.message
                    : undefined}
                </FormErrorMessage>
              </FormControl>
              <FormControl mt={4} isInvalid={!!errors.azureAnalyticsId}>
                <FormLabel>Azure Log Analytics Id</FormLabel>
                <Input
                  value={state?.azureAnalyticsId}
                  placeholder="00000000-0000-0000-0000-000000000000"
                  {...register("azureAnalyticsId")}
                  onChange={(e) => {
                    setState((prevState: any) => {
                      return {
                        ...prevState,
                        azureAnalyticsId: e.target.value,
                      };
                    });
                  }}
                />
              </FormControl>
              <FormControl mt={4} isInvalid={!!errors.powerBIReportId}>
                <FormLabel>PowerBI Report Id</FormLabel>
                <Input
                  value={state?.powerBIReportId}
                  placeholder="00000000-0000-0000-0000-000000000000"
                  {...register("powerBIReportId")}
                  onChange={(e) => {
                    setState((prevState: any) => {
                      return {
                        ...prevState,
                        powerBIReportId: e.target.value,
                      };
                    });
                  }}
                />
              </FormControl>
              <FormControl mt={4} isInvalid={!!errors.powerBIUsageReportId}>
                <FormLabel>PowerBI Usage Report Id</FormLabel>
                <Input
                  value={state?.powerBIUsageReportId}
                  placeholder="00000000-0000-0000-0000-000000000000"
                  {...register("powerBIUsageReportId")}
                  onChange={(e) => {
                    setState((prevState: any) => {
                      return {
                        ...prevState,
                        powerBIUsageReportId: e.target.value,
                      };
                    });
                  }}
                />
              </FormControl>
              <FormControl mt={4} isInvalid={!!errors.crmCustomerId}>
                <FormLabel>CRM Customer Id</FormLabel>
                <Input
                  value={state?.crmCustomerId}
                  placeholder="00000000000"
                  {...register("crmCustomerId")}
                  onChange={(e) => {
                    setState((prevState: any) => {
                      return {
                        ...prevState,
                        crmCustomerId: e.target.value,
                      };
                    });
                  }}
                />
              </FormControl>
              <FormControl mt={4} isInvalid={!!errors.customerShortName}>
                <FormLabel>Customer Short Name</FormLabel>
                <Input
                  value={state?.customerShortName}
                  id="customerShortName"
                  placeholder="PTAKN"
                  maxLength={5}
                  {...register("customerShortName", {
                    minLength: {
                      value: 5,
                      message: "Must be exactly 5 characters",
                    },
                    maxLength: {
                      value: 5,
                      message: "Must be exactly 5 characters",
                    },
                  })}
                  onChange={(e) => {
                    setState((prevState: any) => {
                      return {
                        ...prevState,
                        customerShortName: e.target.value.toLowerCase(),
                      };
                    });
                  }}
                />
                <FormErrorMessage>
                  {errors.customerShortName &&
                  typeof errors.customerShortName.message === "string"
                    ? errors.customerShortName.message
                    : undefined}
                </FormErrorMessage>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Active</FormLabel>
                <Switch
                  isChecked={state?.active}
                  {...register("active")}
                  onChange={(e) => {
                    setState((prevState: any) => {
                      return {
                        ...prevState,
                        active: e.target.checked,
                      };
                    });
                  }}
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button onClick={onClose} mr={3} fontWeight="medium">
                Cancel
              </Button>
              <Button
                isLoading={isSubmitting}
                colorScheme="orange"
                fontWeight="medium"
                type="submit"
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}
    </>
  );
};

export default EditTenantModal;
